@import "src/assets/styles/common/mixins";

.container {
	@include flexbox(center, center);
	width: 100%;
	flex: 1 1;
	min-height: 100vh;
	min-width: 100vw;
	width: 100vw;
	height: 100vh;

	> div {
		width: 100vw;
		height: 100vh;
	}
}
