@import "src/assets/styles/common/mixins";

.card {
	@include card();
	padding: 15px 20px;
	margin: 0;
}

.card_container {
	@include flexbox(flex-start, flex-start, column);
}

.card_content {
	@include flexbox(flex-start, center);
	@include max-w(md) {
		@include flexbox(flex-start, flex-start);
	}
}

.profile_avatar {
	@include flexbox(center, center);

	i {
		font-size: 48px;
		color: var(--icon-primary);
	}

	&.avatarColor {
		i {
			color: var(--ui-profile-avatar-background) !important;
		}
	}

	@include max-w(xs) {
		i {
			font-size: 42px;
		}
	}
}

.row_group {
	@include flexbox(flex-start, flex-start, column);
	margin-left: 20px;
}

.row {
	@include flexbox(flex-start, center);
	//gap: 22px;
	flex-wrap: wrap;

	&:first-child {
		margin-bottom: 5px;
	}

	span:not(:last-of-type) {
		margin-right: 22px;
	}

	a {
		margin-left: 10px;
		i {
			font-size: 16px;
		}
	}
}

.profile_email {
	font-size: 16px;
	line-height: 23px;
	color: var(--color-primary);
}

.profile_uid {
	font-size: 14px;
	line-height: 1.3;
	color: var(--color-primary);
}

.vip_status {
	@include flexbox(flex-start, center);
	color: var(--color-gold);
	font-size: 16px;

	i {
		color: var(--color-gold);
		font-size: 16px;
		margin: 0 4px 4px 0;
	}
}

.span_secondary {
	font-size: 14px;
	line-height: 19px;
	color: var(--color-secondary);
}
