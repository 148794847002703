@import "src/assets/styles/common/mixins";

.container {
	@include card();
	@include flexbox(flex-start, flex-start, column);
	max-width: 550px;
	min-height: 550px;
	width: 100%;
	padding: 0;
	position: relative;
	overflow: visible;

	> img {
		margin: -70px auto 0;
	}
}

.tabs {
	> div {
		max-width: unset;
	}
}

.header {
	@include flexbox(flex-start, center, column);
	width: 100%;
	padding: 12px;

	h1 {
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		line-height: 28px;
		text-align: center;
		color: var(--color-primary);
		margin: 0;
	}
}

.description {
	width: 100%;
	text-align: center;
	padding: 16px 64px;
	font-weight: normal;
	font-size: 14px;
	line-height: 19px;
	background: var(--stripped-normal);
	color: var(--color-secondary);

	@include max-w(xs) {
		padding: 22px 34px;
	}
}

.form_container {
	display: grid;
	grid-template: auto / 1fr;
	padding: 10px 100px 52px;
	width: 100%;
	grid-gap: 20px;

	@include max-w(xs) {
		padding: 10px 25px;
	}

	> button {
		margin-top: 10px;
	}
}

.attribute_container {
	@include flexbox(flex-start, flex-start, column);
	width: 100%;
	border-bottom: 1px solid var(--divider-border);

	&:last-child {
		border-bottom: 0;
	}

	@include max-w(xs) {
		padding: 18px;
	}
}

.attribute {
	width: 100%;
	display: grid;
	grid-template: auto / 1fr 1fr;
	grid-gap: 30px;

	> span {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 19px;

		&:nth-child(1) {
			text-align: left;
			color: var(--color-secondary);
		}

		&:nth-child(2) {
			@include flexbox(flex-end, flex-start);
			text-align: right;
			color: var(--color-primary);
			font-weight: 500;

			> button {
				margin-left: 5px;
			}
		}
	}
}

.form_attributes_container {
	@include flexbox(flex-start, flex-start, column);
	width: 100%;
}

.success_screen {
	@include flexbox(center, center, column);
	padding: 24px 16px;
	width: 100%;

	i[class~="ai"] {
		font-size: 64px;
		color: var(--color-green);
		margin-bottom: 14px;
	}

	span {
		font-size: 16px;
		color: var(--color-secondary);
		line-height: 19px;
		text-align: center;
	}
}

.external_link {
	padding: 10px;
	font-size: 16px;
	line-height: 130%;
	font-weight: 500;
	text-align: center;
	word-break: break-all;
	align-self: center;
	margin-top: 20px;
}
