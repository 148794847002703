.external_link {
	background: var(--stripped-normal);
	padding: 10px;
	border-radius: 6px;
	font-size: 14px;
	line-height: 130%;
	width: 100%;
	font-weight: 500;
	color: var(--color-secondary);
	text-align: center;
	word-break: break-all;
}
