@import "src/assets/styles/common/mixins";

.container {
}

.widget {
	@include flexbox(flex-start, flex-start, column);
	@include card(false);
	margin: 0;
	position: relative;
	padding: 0 0 6px;
	height: 100%;
}

.widget_title {
	display: inline-flex;
	align-items: center;
	font-weight: bold;
	font-size: 12px;
	line-height: 14px;
	text-transform: uppercase;
	color: var(--color-secondary);
	margin-left: 6px;
}

.widget_header {
	@include flexbox(space-between);
	font-size: 13px;
	padding: 4px;
	border-radius: 8px 8px 0 0;
	height: 30px;
	max-height: 30px;
}

.widget_divider {
	height: 1px;
	overflow: hidden;
	background: var(--divider-border);
	flex: 0 1 auto;
}

.widget_loader {
	position: absolute;
	z-index: 9;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: var(--card-background-color);
	@include flexbox(center, center);
}

.widget_loading_overlay {
	@include flexbox(center, center);
	position: absolute;
	z-index: 9;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: var(--card-background-color);
	opacity: 0.3;
}

.orders_group {
	grid-area: orders;
}

.order_form_widget {
	grid-area: order-form;
}

.chat_wallet_trades_widget {
	grid-area: chat-wallet-trades;
	height: auto;
	display: grid;

	> div {
		height: 100%;
	}
}

.orders_widget {
	grid-area: orders-history;
}

.sell_orders {
	grid-area: sell-orders;
	overflow: visible;
	padding: 0;
}

.buy_orders {
	grid-area: buy-orders;
	overflow: visible;
	padding: 0;
}

.chart_coin_info_widget {
	grid-area: chart-coin-info;
	overflow: visible;

	@include min-max-w(xxl, 1440px) {
		overflow: hidden;
	}
}

.tickers {
	grid-area: tickers;
	overflow: hidden;
}

.order_form_widget_advanced {
	grid-area: order-form-advanced;
}

.positions_widget {
	grid-area: positions;
}

.orders_history_positions {
	grid-area: orders-history-positions;
	display: grid;
	grid-template: auto 1fr / 1fr;
	grid-gap: 8px;
	grid-template-areas:
		"positions"
		"orders-history";

	> div {
		height: auto;
	}
}

.chart_chat_wallet_basic {
	display: grid;
	grid-column: 1 / 4;
	grid-template-columns: 2.5fr 1fr;
	grid-gap: 8px;

	> div:first-child {
		grid-column: 1 / 2;
		grid-row: 1;
	}

	@include max-w(xl) {
		grid-template-columns: 2fr 1fr;
	}

	@include max-w(lg) {
		grid-template-rows: 400px;
		grid-template-columns: auto 275px;
	}
}

.sell_buy_form {
	display: grid;
	grid-template: 350px auto / 1fr 1fr;
	grid-gap: 8px;
	grid-column: 1 / 4;
	grid-template-areas:
		"sell-orders buy-orders"
		"order-form  order-form";
}

.grid_basic {
	width: 100%;
	background: transparent;
	display: grid;
	grid-template-columns: 1fr 2.5fr 1fr;
	grid-template-rows: 400px 400px auto;
	grid-gap: 8px;
	padding: 8px;
	box-sizing: border-box;
	max-width: 1650px;
	margin: 0 auto;
	position: relative;
	grid-template-areas:
		"tickers-chart-chat-wallet tickers-chart-chat-wallet tickers-chart-chat-wallet"
		"sell-orders order-form buy-orders"
		"orders-history orders-history orders-history";

	@include max-w(xxl) {
		grid-template-columns: 1fr 1.2fr 1fr;
		grid-template-rows: 435px auto auto;
	}

	@include max-w(lg) {
		grid-template-rows: auto;
	}
}

.tickers_chart_chat_wallet_basic {
	display: grid;
	grid-area: tickers-chart-chat-wallet;
	grid-template-columns: 35px calc(77.75% - 50px) 1fr;
	grid-gap: 8px;
	grid-template-rows: 100%;
	grid-template-areas: "tickers chart-coin-info chat-wallet-trades";

	&.expanded {
		grid-template-columns: 1fr 2.5fr 1fr;

		@include max-w(xxl) {
			grid-template-columns: 1fr 2fr 1fr;
		}
	}
}

.grid_standard {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 330px 330px;
	grid-template-rows: 390px 390px auto;
	grid-gap: 8px;
	padding: 8px;
	margin: 0 auto;
	box-sizing: border-box;
	position: relative;
	grid-template-areas:
		"chart-coin-info orders tickers-trades"
		"order-form orders tickers-trades"
		"orders-history orders-history orders-history";

	@include max-w(xl) {
		grid-template-columns: repeat(3, 1fr);
	}

	@include min-w(1921px) {
		grid-template-rows: 346px 346px auto;
		grid-template-areas:
			"chart-coin-info orders tickers-trades"
			"chart-coin-info orders tickers-trades"
			"orders-history order-form order-form";
	}
}

.tickers_trades_standard {
	display: grid;
	grid-column: 3 / 4;
	grid-template-columns: 1fr;
	grid-template-rows: 35px 1fr;
	grid-gap: 8px;
	grid-area: tickers-trades;
	grid-template-areas:
		"tickers tickers"
		"chat-wallet-trades chat-wallet-trades";

	&.expanded {
		grid-template-rows: 1fr 1fr;
	}
}

.tickers_chart_advanced {
	display: grid;
	grid-template-columns: 70px 1fr;
	grid-gap: 8px;
	grid-area: tickers-chart;
	grid-template-areas: "tickers chart-coin-info";

	&.expanded {
		grid-template-columns: 350px 1fr;
	}

	@include max-w(1820px) {
		grid-template-columns: 1fr;
		grid-template-areas: "chart-coin-info";

		&.expanded {
			grid-template-columns: initial;
		}
	}
}

.grid_advanced {
	width: 100%;
	max-width: 2560px;
	margin: 0 auto;
	grid-gap: 8px;
	padding: 8px;
	box-sizing: border-box;
	position: relative;
	display: grid;
	grid-template-rows: 735px 1fr;
	grid-template-columns: minmax(675px, 1fr) minmax(280px, 350px) 360px;
	grid-template-areas:
		"tickers-chart orders-trades order-form-advanced"
		"orders-history orders-history order-form-advanced";

	&.positions {
		grid-template-areas:
			"tickers-chart orders-trades order-form-advanced"
			"orders-history-positions orders-history-positions order-form-advanced";
	}

	@include max-h(1080px) {
		grid-template-rows: 645px 1fr;
	}

	@include max-h(1024px) {
		grid-template-rows: 565px 1fr;
	}

	@include max-w(xxxl) {
		grid-template-columns: minmax(590px, 1fr) minmax(225px, 320px) minmax(225px, 350px);
	}

	@include max-w(1820px) {
		grid-template-columns: minmax(590px, 1fr) minmax(225px, 320px) minmax(225px, 310px);
	}
}

.chart_orders_history_fullscreen {
	display: grid;
	grid-row-gap: 8px;
	grid-area: chart-orders-history;
	grid-template-rows: 510px auto;
	grid-template-columns: 1fr;
	grid-template-areas:
		"chart-coin-info chart-coin-info"
		"orders-history orders-history";

	> div {
		height: auto;
		display: grid;
		> div:first-child {
			height: auto;
		}
		&:nth-child(2) > div:first-child {
			overflow: auto;
		}
	}

	@include max-w(xl) {
		grid-template-rows: 450px auto;
	}

	@include max-w(lg) {
		grid-template-rows: 400px auto;
	}
}

.orderbook_trades_advanced {
	grid-area: orders-trades;
	@include card();
	overflow: visible;
	display: flex;
	flex-direction: column;
	padding: 0;
	margin: 0;
	height: 100%;
}

.orderbook_trades_advanced_content {
	display: grid;
	grid-template: 1fr / 1fr;
	height: 100%;
	border-radius: 0 0 8px 8px;

	&.grouped {
		grid-template: 390px auto / 1fr;

		.orderbook_container {
			grid-row: 1;
		}

		@include max-h(1024px) {
			grid-template: 360px auto / 1fr;
		}
	}

	> div {
		border: none;
		height: auto;

		> div:first-child {
			border-radius: 0;
		}
	}
}

.tabs {
	@include flexbox(flex-start, center);
	position: relative;
	height: 30px;
	min-height: 30px;
	width: 100%;
	border-bottom: 1px solid var(--divider-border);

	> div:only-child {
		max-width: 100%;
	}
}

.tickers_filter {
	padding: 5px;
	width: 100%;
	display: grid;
	grid-template: auto / 1fr auto;
	border-bottom: 1px solid var(--divider-border);

	> div {
		padding-top: 0;
	}

	> div:nth-child(2) {
		margin-left: 20px;
	}
}

.tickers_filter_switch {
	> label {
		width: 50px;
		min-width: 50px;
		height: 28px;
		min-height: 28px;
		border-color: var(--switch-label-normal);
	}

	&.checked > label {
		background-color: var(--color-blue);
		border-color: var(--color-blue);

		> span {
			background-color: #fff;
		}
	}
}

.margin_leverate_sign {
	font-size: 10px;
	line-height: 12px;
	font-style: normal;
	font-weight: normal;
	display: inline-flex;
	align-items: center;
	text-align: center;
	margin-left: 4px;
	padding: 0 4px;
	border-radius: 3px;
	border: 1px solid;
	border-color: var(--color-yellow);
	color: var(--color-yellow);
}

.table_container {
	width: 100%;
	display: block;
	flex-direction: column;
	flex: 1 0 0;
	overflow: hidden auto;
	border-radius: 0 0 5px 5px;
	position: relative;
}

// TICKERS
.tickers_tabs {
}

.tickers_widget {
	overflow: visible;
	padding: 0 2px;

	&.absolute {
		position: absolute;
		top: 35px;
		left: 0;
		z-index: 9;
		height: 400px;
		border-radius: 0 0 8px 8px;
		border-top: none;
		width: 320px;
		box-shadow: var(--navmenu-box-shadow);
	}

	&.mobile {
		box-shadow: none;
		padding-bottom: 0;
		width: 100%;
		border: none;
		flex: 1 0 auto;
		border-radius: 0;
	}

	&.collapsed {
		align-items: center;
		justify-content: space-between;
		padding: 20px 0 60px 2px;

		&:not(.standard) {
			.tickers_tabs {
				transform: rotate(-90deg) translate(calc(-50% + 15px), 18px);
				flex-direction: row;
				position: absolute;
				border: none;
				top: 0;
				width: auto;
				height: auto;

				> div {
					padding: 0 15px;
					max-width: 180px;

					i[class~="ai-star_outlined"] {
						transform: rotate(90deg);
					}
				}

				&:nth-child(1) {
					transform: rotate(-90deg) translate(calc(-50% + 15px), 0);
				}

				&:nth-child(2) {
					transform: rotate(-90deg) translate(calc(-50% + 16px), 18px);
					border-top: 1px solid var(--divider-border);
				}

				@include max-h(1080px) {
					width: auto;
				}

				@include max-h(1024px) {
					width: auto;
				}
			}
		}

		&.standard {
			padding: 2px 2px 0;

			.tickers_tabs {
				border: none;
			}
		}

		&.advanced {
			.tickers_tabs {
				width: 732px;
				height: 30px;

				&:nth-child(1) {
					transform: rotate(-90deg) translate(-350px, -18px);
				}

				&:nth-child(2) {
					transform: rotate(-90deg) translate(-350px, 16px);
				}

				> div {
					max-width: unset;
				}

				@include max-h(1080px) {
					&:nth-child(1) {
						transform: rotate(-90deg) translate(-302px, -18px);
					}

					&:nth-child(2) {
						transform: rotate(-90deg) translate(-302px, 16px);
					}

					width: 641px;
				}

				@include max-h(1024px) {
					&:nth-child(1) {
						transform: rotate(-90deg) translate(-262px, -18px);
					}

					&:nth-child(2) {
						transform: rotate(-90deg) translate(-262px, 16px);
					}

					width: 551px;
				}
			}
		}
	}
}

.tickers_table {
	height: 100%;
	padding-bottom: 0;
}

i.tickers_favorites {
	font-size: 18px;
	color: var(--color-secondary);

	&.active {
		color: var(--color-blue);
	}
}

.tickers_table_row {
	cursor: pointer;
	user-select: none;
	padding: 6px 8px;
	height: 39px;
	color: var(--color-primary);

	> div {
		font-size: 12px;
	}

	&.active {
		background-color: var(--color-blue) !important;

		&:hover {
			background-color: var(--color-blue) !important;
		}

		.tickers_table_row_change.green {
			color: var(--orderbook-active-green);
		}

		i.favorite {
			color: #fff;
		}

		> div,
		.tickers_table_row_pair_name,
		.tickers_table_row_pair_currency_name_full {
			color: #fff;
		}
	}
}

.tickers_table_row_pair {
	@include flexbox(flex-start, center);
	pointer-events: all !important;
	min-width: 85px;
	font-family: "Roboto", sans-serif;
	font-size: 12px;

	> a.star {
		max-width: 30px;
	}

	> i {
		font-size: 17px;
		margin-bottom: 3px;
		color: #afafaf;

		&.active {
			color: #fff;
		}

		&.favorite {
			color: var(--color-blue);
		}
	}

	&.favorite {
		color: var(--color-blue);
	}
}

.tickers_table_row_pair_column_name {
	display: flex;
	flex-direction: column;
	margin-left: 5px;
	line-height: 14px;
	font-family: "Roboto", sans-serif;
	font-size: 12px;
}

.tickers_table_row_pair_name_full {
	font-weight: bold;
	display: block;
	position: relative;
	width: max-content;

	@include min-max-w(1100px, 1240px) {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 25px;
	}
}

.tickers_table_row_currency_name {
	font-weight: bold;
	display: block;
	position: relative;
	width: max-content;

	@include min-max-w(1100px, 1240px) {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 25px;
	}
}

.tickers_table_row_pair_name {
	color: var(--color-secondary);
	font-size: 11px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 65px;

	@include min-max-w(1100px, 1240px) {
		max-width: 40px;
	}

	&.primary {
		color: var(--colo-primary);
	}
}

.tickers_table_row_pair_liquidity_sign {
	margin-left: 4px;
	display: inline-block;
	position: relative;
}

.tickers_table_row_change {
	color: var(--colo-primary);

	&.red {
		color: var(--orderbook-red-primary);
	}

	&.green {
		color: var(--orderbook-green-primary);
	}

	padding-right: 8px;
}

.tickers_expand_btn {
	@include flexbox(center, center);
	cursor: pointer;
	height: 26px;
	width: 20px;
	border-radius: 6px;
	border: 1px solid var(--card-outlined-border-color) !important;
	background: var(--icon-button-background-color);
	transition: background 0.12s ease-out;
	z-index: 43;
	position: absolute;
	bottom: 20px;

	> i {
		color: var(--icon-button-color);
		transform: translateX(-1px);
		font-size: 9px;
	}

	&:hover {
		background: var(--icon-button-background-color-hover);
		border-color: var(--icon-button-border-color-hover) !important;

		> i {
			color: var(--icon-button-color-hover);
		}
	}

	&.expanded {
		bottom: 15px;
		left: -6px;
		right: initial;

		&.basic,
		&.right {
			bottom: 15px;
			right: -4px;
			left: initial;
		}
	}

	&.standard {
		bottom: -12px;
		left: 10px;
		transform: rotate(90deg);

		> i {
			transform: translateX(-3px);
		}
	}
}

// RECENT TRADES
.recent_trades {
	@include flexbox(flex-start, flex-start, column);
	width: 100%;
	position: relative;
	flex: 1 1;
}

.recent_trades_table_wrapper {
	flex: 1 0 auto;
	position: relative;
	width: 100%;
}

.recent_trades_table {
	padding-bottom: 0;
}

.recent_trades_list {
	display: block;
	outline: none;
	font-size: 12px;
}

.recent_trades_list_row {
	height: 22px;
	font-size: 12px;
	flex: 1;

	> div {
		font-size: 12px;
	}

	.formatted_price {
		font-weight: 500;
	}
}

.formatted_price {
	@include flexbox(flex-start, center);
	width: 100%;
	pointer-events: none;
	font-size: 12px;
	white-space: nowrap;
	font-weight: 500;

	&.buy {
		color: var(--orderbook-green-secondary);

		> span {
			color: var(--orderbook-green-primary);
		}
	}

	&.sell {
		color: var(--orderbook-red-secondary);

		> span {
			color: var(--orderbook-red-primary);
		}
	}
}

// WALLETS

.wallets {
	@include flexbox(flex-start, flex-start, column);
	width: 100%;
	height: 100%;
	position: relative;
	flex: 1 1;
	overflow: auto;
}

.wallets_row {
	height: 42px;
}

.wallets_row_currency {
	display: flex;
	flex-direction: row;
	align-items: center;

	img {
		width: 20px;
		height: 20px;
		margin-right: 8px;
	}

	i {
		margin-right: 8px;
		font-size: 20px;
	}
}

.wallets_row_currency_primary {
	font-weight: bold;
	color: var(--color-primary);
	font-size: 12px;
}

.wallets_row_currency_secondary {
	font-size: 11px;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: var(--color-secondary);

	@include max-w(xl) {
		max-width: 80px;
	}

	@include max-w(md) {
		max-width: 100px;
	}
}

.wallets_row_num {
	font-size: 12px;
	font-style: normal;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: var(--color-primary);
}

.wallets_row_balance {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.wallets_row_value {
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	&.left {
		align-items: flex-start;
	}
}

.wallets_row_deposit_btn {
	display: flex;
	width: 100%;
	justify-content: flex-end;
	align-items: center;
	cursor: pointer;

	i {
		font-size: 14px;
		color: var(--color-blue);
	}
}

.wallets_accounts_link {
	@include flexbox(center, center);
	padding: 0 20px;
	min-height: 46px;
	text-align: center;
	margin-top: auto;
	width: 100%;

	a {
		width: 100%;
	}
}

// COIN INFO
.coin_info_container {
	@include flexbox(flex-start, center);
	padding: 0 15px;
	height: 30px;
	overflow: overlay;
	width: 100%;
	border-bottom: 1px solid var(--divider-border);

	&::-webkit-scrollbar {
		width: 0;
		height: 0;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background: transparent;
	}

	> i {
		font-size: 16px;
		line-height: 16px;
		color: var(--color-blue);
		margin: 0 6px 0 3px;
	}
}

.coin_info_coin_name {
	@include flexbox(flex-start, center);
	font-size: 14px;
	font-weight: bold;
	margin: 0 25px 0 0;
	width: auto;
	user-select: none;
	cursor: pointer;

	@include max-w(xxl) {
		margin: 0 0 0 5px;
	}

	@include max-w(xl) {
		color: var(--color-blue);
		font-size: 14px;

		i {
			color: var(--color-blue);
		}
	}

	i {
		font-size: 10px;
		line-height: 10px;
		margin: 0 10px 0 5px;
	}

	&:hover i {
		color: var(--color-primary);
	}
}

.coin_info_quote_currency {
	font-weight: normal;
	font-size: 14px;
	color: var(--color-secondary);
	width: max-content;

	@include max-w(xl) {
		font-size: 14px;
		color: var(--color-blue);
		font-weight: bold;
	}
}

.margin_leverage_sign {
	font-size: 11px;
	border-radius: 2px;
	border: 1px solid var(--color-yellow);
	color: var(--color-yellow);
	padding: 0 3px;
	margin-left: 4px;
}

.coin_info_content {
	@include flexbox(flex-start, center);
	list-style-type: none;
	margin: 0;
	padding: 0;
	padding-inline-start: 0;
	padding-inline-end: 0;
	margin-block: 0;
	margin-block-end: 0;

	@include min-max-w(md, xl) {
		font-size: 12px;
		overflow-x: auto;
	}

	li {
		@include flexbox(flex-start, center);
		padding: 0 10px;
		flex: 1 0 auto;
		flex-direction: row;
		color: var(--icon-primary);
		font-size: 14px;
		user-select: none;
		cursor: pointer;
		position: relative;

		i {
			font-size: 12px;
			margin-right: 3px;

			&[class~="ai-arrow_down"],
			&[class~="ai-arrow_up"] {
				font-size: 10px;
				margin-bottom: 2px;
			}
		}

		> a {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}

		@include max-w(lg) {
			padding: 0 5px;
		}
	}
}

.coin_info_param_name {
	display: inline-block;
	margin-right: 3px;
	color: var(--icon-primary);
}

.coin_info_quick_order_btn {
	margin-left: auto;
	padding-left: 10px;

	i {
		font-size: 14px;
	}

	&.active i {
		color: var(--color-blue);
	}
}

.coin_info_param_value {
	display: inline-block;
	color: var(--color-primary);

	&.green {
		color: var(--orderbook-green-primary);
	}

	&.red {
		color: var(--orderbook-red-primary);
	}
}

.coin_info_skeleton {
	@include flexbox(flex-start, center);

	> div {
		margin-right: 20px;
	}
}

// ORDERBOOK
.orderbook_list_container {
	display: flex;
	width: 100%;
	flex-direction: column;
	padding: 0 0 8px;
	margin: 0;
	box-shadow: none;
	border: none;
	position: relative;
	height: 100% !important;
	overflow: hidden;

	&:hover {
		overflow: visible;
	}

	&.grouped {
		border: none;
		position: relative;
		height: 100% !important;

		&:nth-child(2) {
			border-radius: 0;
			padding-bottom: 0;
		}

		&:nth-child(3) {
			border-radius: 0 0 8px 8px;
		}

		.widget_title {
			font-weight: normal;
			text-transform: unset;
		}

		.widget_header {
			padding: 8px 4px 8px 10px;
			height: 30px;
			min-height: 30px;
			border-radius: 0;
		}
	}
}

.orderbook_content {
	@include flexbox(stretch, stretch, column);
	border-radius: 0;
	overflow: inherit;
	padding: 0;
	margin: 0;
	box-shadow: none;
	border: none;
	position: relative;
	height: 100% !important;

	&.hidden {
		overflow: hidden;
	}
}

.orderbook_table {
	padding: 0;
}

.orderbook_table_header {
	height: 30px;
	min-height: 30px;
}

.orderbook_list_wrapper {
	flex: 1 0 auto;
	position: relative;
}

.orderbook_list {
	display: block;
	outline: none;
	font-size: 12px;
	border-radius: 0 0 5px 5px;
}

.orderbook_group_list {
	width: 100%;
	height: 100%;
	overflow: hidden;
	outline: none;
	font-size: 12px;
}

.orderbook_list_item {
	min-height: 22px;
	height: 22px;
	background-color: inherit;
	font-size: 12px;
	cursor: pointer;
	padding: 0 12px 0 8px;
	pointer-events: all;

	&:hover {
		background-color: var(--orderbook-hover-background) !important;

		.orderbook_list_item_column.price > div {
			font-size: 14px;
			font-weight: bold;
		}
	}

	&.sum_idx_bg {
		background-color: var(--orderbook-hover-background) !important;
	}
}

.orderbook_list_item_active_order {
	position: absolute;
	z-index: 101;
	background: var(--color-blue);
	border-radius: 50%;
	top: 7px;
	left: 4px;
	width: 8px;
	height: 8px;
	pointer-events: none;
}

.orderbook_list_item_column {
	flex: 1;
	text-align: left;
	pointer-events: none;
	padding: 0 2px;
	z-index: 2;

	&.center {
		text-align: center;
	}

	&.right {
		text-align: right;
	}

	&.price {
		@include flexbox();
		cursor: pointer;
		z-index: 2;
		pointer-events: all !important;
		font-weight: 500;

		div,
		i {
			pointer-events: none;
		}
	}
}

.orderbook_list_item_depth_progress {
	position: absolute;
	top: 0;
	width: 0;
	height: 100%;
	z-index: 1;
	pointer-events: none;

	&.left {
		left: 0;
		right: unset;
	}

	&.right {
		right: 0;
		left: unset;
	}

	&.sell {
		background-color: var(--orderbook-red);
	}

	&.buy {
		background-color: var(--orderbook-green);
	}
}

.orderbook_dashed_bottom_line {
	position: absolute;
	bottom: 0;
	top: initial;
	height: 1px;
	left: 0;
	right: 0;
	background-image: linear-gradient(to right, transparent 20%, var(--color-blue) 0%);
	background-position: bottom;
	background-size: 10px 1px;
	background-repeat: repeat-x;
	z-index: 41;
	pointer-events: none;

	&.top {
		bottom: initial;
		top: 0;
	}
}

.orderbook_container {
	@include card();
	display: grid;
	grid-row: 1 / 3;
	grid-template: 30px 1fr 35px 1fr / 1fr;
	position: relative;
	padding: 0;
	margin: 0;
	height: 100%;
	overflow: visible;

	&.sell {
		grid-template: 30px 1fr 0 / 1fr;
	}

	&.buy {
		grid-template: 30px 0 1fr / 1fr;
	}

	&.group {
		grid-template: 30px 1fr 35px 1fr / 1fr;
	}
}

.orderbook_header {
	@include flexbox(space-between, center);
	width: 100%;
	height: 100%;
	padding: 8px 0 8px 10px;
	border-radius: 8px 8px 0 0;
	background: var(--stripped-normal);
}

.orderbook_precision {
	@include flexbox(center, center);
	margin: 0 10px 0 auto;
	position: relative;
	width: 80px;
	height: auto;
}

.orderbook_precision_value {
	@include flexbox(center, center);
	height: 22px;
	text-align: center;
	width: 80px;
	border-radius: 4px;
	color: var(--color-primary);
	font-size: 12px;
	cursor: pointer;

	> i {
		font-size: 6px;
		margin-left: 2px;
		color: var(--colo-primary);
	}
}

.orderbook_precision_dropdown {
	@include flexbox(flex-start, center, column);
	position: absolute;
	top: calc(100% + 1px);
	z-index: 999;
	left: 0;
	right: 0;
	margin-top: 0;
	border-radius: 6px;
	overflow: hidden;
	background: var(--navmenu-background);
	box-shadow: var(--navmenu-box-shadow);
	width: 100%;

	> button {
		@include flexbox(center, center);
		height: 24px;
		width: 100%;
		padding: 6px;
		font-size: 12px;
		text-align: center;
		color: var(--color-primary);

		&:hover {
			background-color: var(--navmenu-item-hover);
		}
	}
}

.orderbook_rate_info {
	@include flexbox(space-between, center);
	height: auto;
	background: var(--stripped-normal);
	width: 100%;
	font-size: 16px;
	padding: 0 14px;
	position: relative;

	i[class~="ai-translation"] {
		font-size: 18px;
		color: var(--orderbook-green-primary);

		&.ws_down {
			i[class~="ai-translation"] {
				color: var(--orderbook-red-primary);
			}
		}
	}

	span {
		color: var(--color-primary);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: auto;
		max-width: 160px;
		font-size: 16px;
		cursor: pointer;

		&:nth-child(1) {
			i {
				margin-left: 5px;
				font-size: 8px;
				color: var(--color-primary);
			}
		}

		&:nth-child(2) {
			color: var(--color-primary);
			max-width: 100px;
		}
	}
}

.up {
	i {
		color: var(--orderbook-green-primary) !important;
	}

	&:nth-child(1),
	&:nth-child(2) {
		color: var(--orderbook-green-primary) !important;
	}
}

.down {
	i {
		color: var(--orderbook-red-primary) !important;
	}

	&:nth-child(1),
	&:nth-child(2) {
		color: var(--orderbook-red-primary) !important;
	}
}

.orderbook_view_options {
	@include flexbox(flex-end, center);
	margin-right: 10px;
	height: 100%;
}

.orderbook_view_option {
	height: 22px;
	width: 22px;
	border-radius: 5px;
	transition: 0.2s ease;
	cursor: pointer;
	display: grid;
	justify-items: end;
	padding: 3px;
	border: 2px solid transparent;

	span {
		width: 100%;
		height: auto;
		transition: 0.12s ease;
		border-radius: 2px;
	}

	&:nth-of-type(1) {
		grid-gap: 2px;
		span {
			&:nth-child(1) {
				background: var(--orderbook-option-red-alpha);
			}
			&:nth-child(2) {
				background: var(--orderbook-option-green-alpha);
			}
		}
	}

	&:nth-of-type(2) {
		span {
			background: var(--orderbook-option-red-alpha);

			&:first-child {
				border-radius: 2px 2px 0 0;
			}
			&:last-child {
				border-radius: 0 0 2px 2px;
			}
		}
	}

	&:nth-of-type(3) {
		span {
			background: var(--orderbook-option-green-alpha);

			&:first-child {
				border-radius: 2px 2px 0 0;
			}
			&:last-child {
				border-radius: 0 0 2px 2px;
			}
		}
	}

	&.active {
		border-color: var(--color-blue);

		&:nth-child(1) {
			span {
				&:nth-child(1) {
					background: var(--orderbook-option-red);
				}
				&:nth-child(2) {
					background: var(--orderbook-option-green);
				}
			}
		}

		&:nth-of-type(2) {
			span {
				background: var(--orderbook-option-red);
			}
		}

		&:nth-of-type(3) {
			span {
				background: var(--orderbook-option-green);
			}
		}
	}

	&:hover {
		&:nth-of-type(1) {
			span {
				&:nth-child(1) {
					background: var(--orderbook-option-red);
				}
				&:nth-child(2) {
					background: var(--orderbook-option-green);
				}
			}
		}

		&:nth-of-type(2) {
			span {
				background: var(--orderbook-option-red);
			}
		}

		&:nth-of-type(3) {
			span {
				background: var(--orderbook-option-green);
			}
		}
	}
}

.orderbook_total_value_container {
	margin-right: 10px;
}

.orderbook_total_value {
	font-weight: bold;
	color: var(--color-primary);
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	display: inline-block;
	vertical-align: bottom;
	max-width: 115px;
}

.orderbook_total_value_currency {
	color: var(--color-secondary);
}

.orderbook_tooltip_param_name {
	color: var(--orderbook-param-name);
	margin-right: 5px;
}

.orderbook_tooltip_param_value {
	color: var(--tooltip-color);
	font-weight: bold;
	margin-left: auto;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.orderbook_tooltip_param_row {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 14px;
	line-height: 16px;
	width: 100%;
}

.orderbook_tooltip_container {
	display: grid;
	grid-template: auto / 1fr;
	grid-gap: 8px;
	padding: 12px 8px;
	min-width: 220px;
	max-width: 300px;
	width: auto;
	z-index: 104;
	box-sizing: border-box;
	left: 100%;
	border-radius: 3px;
	right: initial;
	background-color: var(--tooltip-background);
	position: absolute;

	&.left {
		right: 100%;
		left: initial;

		i {
			right: -7px;
			left: initial;
		}
	}

	i {
		color: var(--tooltip-background);
		position: absolute;
		font-size: 14px;
		top: calc(50% - 7px);
		left: -8px;
		right: initial;
	}
}

// ORDER FORM
.order_form {
	display: flex;
	flex-direction: column;
}

.order_form_divider {
	position: relative;
	height: 100%;

	&:before {
		content: "";
		position: absolute;
		top: 10px;
		background: var(--divider-border);
		width: 1px;
		height: calc(100% - 20px);
	}
}

.order_form_body {
	@include flexbox();
	width: 100%;
	height: 100%;
	padding: 0;
}

.order_form_stop_limit_tooltip {
	max-width: 300px;
}

.order_form_tablet_side_tabs {
	border: none;
	width: 100%;
	padding: 8px;
	margin-bottom: 8px;
	height: auto;
	display: grid;
	grid-template: auto / 1fr 1fr;
	grid-gap: 10px;
}

.order_form_tablet_side_tab {
	background-color: var(--tab-button-backround);
	border: none;
	max-width: unset;
	width: 100%;
	border-radius: 8px;

	&:nth-child(1) {
		color: var(--button-filled-tertiary-background);
	}

	&:nth-child(2) {
		color: var(--button-filled-quinary-background);
	}

	&.active {
		color: #fff;

		&:nth-child(1) {
			background-color: var(--button-filled-tertiary-background);
		}

		&:nth-child(2) {
			background-color: var(--button-filled-quinary-background);
		}
	}
}

.order_form_advanced {
	height: 100%;
}

.order_form_advanced_faq {
	margin-left: auto;
	font-size: 12px;
	font-weight: bold;
	color: var(--color-secondary);
	padding: 0 10px;
	@include flexbox(center, center);

	i {
		font-size: 8px;
		margin-left: 4px;
		color: var(--color-secondary);
	}

	&:hover {
		color: var(--color-blue);

		i {
			color: var(--color-blue);
		}
	}
}

.order_form_buy_sell_options {
	@include flexbox(center, center);
	margin: 0 0 8px;
	padding: 0;
	width: 100%;
	border-radius: 8px 8px 0 0;
	border-bottom: 2px solid;
	border-color: var(--button-filled-quinary-background);

	&.buy {
		border-color: var(--button-filled-tertiary-background);
	}

	@include max-w(md) {
		margin: 0;
	}
}

.order_form_buy_sell_option {
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	position: relative;
	flex: 1;
	color: var(--button-filled-quinary-background);

	&:first-child:before {
		border-radius: 8px 0 0 0;
	}

	&:last-child:before {
		border-radius: 0 8px 0 0;
	}

	span {
		font-weight: bold;
		font-size: 12px;
		line-height: 14px;
		text-align: center;
		text-transform: uppercase;
		z-index: 2;
	}

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: -8px;
		width: calc(100% + 8px);
		height: 0;
		z-index: 1;
		border-top: unset;
		border-bottom: 31px solid rgba(255, 95, 102, 0.1);
		border-right: unset;
		border-left: 15px solid transparent;
	}

	&.active {
		color: #fff;

		&:before {
			border-bottom: 31px solid var(--button-filled-quinary-background);
		}
	}

	&.buy {
		color: var(--button-filled-tertiary-background);

		&:before {
			border-right: 15px solid transparent;
			left: 0;
			border-left: unset;
			border-bottom: unset;
			border-top: 31px solid rgba(83, 185, 135, 0.1);
		}

		&.active {
			color: #fff;

			&:before {
				border-top: 31px solid var(--button-filled-tertiary-background);
			}
		}
	}
}

// TRADE FORM
.trade_form {
	padding: 10px 25px;
	flex: 1;
	width: 100%;
	display: flex;
	flex-direction: column;

	button span {
		font-weight: 500;
	}

	> div:not(:first-child) {
		margin-top: 12px;
	}

	> button {
		margin-top: 20px;
	}

	@include max-w(md) {
		padding: 10px 0;
	}
}

.trade_form_header {
	color: var(--color-primary);
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
	min-height: 22px;
}

.trade_form_title {
	display: inline-block;
	font-weight: bold;
	font-size: 12px;
	text-transform: uppercase;
	color: var(--color-secondary);
	margin: 0 15px 0 0;
}

.trade_form_header_green_bold {
	color: var(--color-green);
	font-weight: 500;
}

.trade_form_header_red_bold {
	color: var(--color-red);
	font-weight: 500;
}

.trade_form_wallet_balance {
	margin-right: 0;
	display: flex;
	align-items: center;
	flex-direction: row;
	color: inherit;
	margin-left: auto;
	cursor: pointer;

	> span {
		color: var(--color-primary);
		font-weight: bold;
		font-size: 14px;
	}

	i {
		font-size: 18px;
		margin: 0 4px 1px 0;
		color: var(--color-secondary);
	}

	&.demo {
		color: var(--color-demo);

		> span {
			color: var(--color-demo);
		}

		i {
			color: var(--color-demo);
		}
	}
}

.trade_form_wallet_balance_slider {
	width: 100%;
	position: relative;
	align-items: center;
	display: flex;
	margin: 18px 0 8px !important;

	&.disabled {
		pointer-events: none;
		opacity: 0.75;
	}

	&:before {
		content: "";
		position: absolute;
		width: 100%;
		left: 0;
		height: 4px;
		background: var(--input-disabled);
	}

	> input {
		-webkit-appearance: none;
		appearance: none;
		width: 100%;
		height: 0;
		margin: 0;
		border-radius: 8px;
		outline: none;
		background: transparent;
		z-index: 3;
		cursor: pointer;

		&::-webkit-slider-thumb {
			-webkit-appearance: none;
			appearance: none;
			width: 14px;
			height: 14px;
			border-radius: 4px;
			margin-top: 1px;
			transform: rotate(45deg);
			background: transparent;
			border: none;
			outline: none;
			cursor: pointer;
		}
	}

	> span {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		top: -10px;
		transform: rotate(45deg);
		background: var(--input-slider-thumb-background);
		border: 2px solid var(--card-background-color);
		position: absolute;
		pointer-events: none;
		z-index: 5;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			border-radius: 50%;
			border: 3px solid var(--input-hover);
			width: 100%;
			height: 100%;
		}
	}
}

.trade_form_range_tooltip_chosen {
	margin-top: 2px !important;
}

.trade_form_range_tooltip {
	margin-top: -3px !important;
}

.trade_form_wallet_balance_slider_progress {
	position: absolute;
	pointer-events: none;
	left: 0;
	background-color: var(--input-hover);
	border-radius: 8px;
	height: 4px;
}

.trade_form_wallet_balance_slider_part {
	@include flexbox(center, center);
	width: 13px;
	height: 13px;
	background-color: var(--input-normal);
	border: 3px solid;
	border-color: var(--card-background-color);
	text-align: center;
	border-radius: 50%;
	cursor: pointer;
	position: absolute;
	z-index: 4;

	&.inactive {
		pointer-events: none;
	}

	&.active {
		width: 11px;
		height: 11px;
		background: var(--input-hover);
		border-color: var(--input-hover);
	}

	@include max-w(md) {
		font-size: 12px;
	}
}

.trade_form_fee_container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	> div {
		display: flex;
		color: var(--color-disabled);
		font-size: 12px;
	}

	.amount {
		width: 100px;
		overflow: hidden;
		text-align: end;
		text-overflow: ellipsis;
	}
}

.trade_form_login_container {
	margin-top: 50px;
	text-align: center;
}

.trade_form_stats {
	margin-top: 80px;
	display: flex;
	flex-direction: column;
	width: 100%;

	@include max-h(1080px) {
		margin-top: 30px;
	}

	@include max-w(md) {
		margin-top: 15px;
	}
}

.trade_form_stats_pair_account {
	@include flexbox(space-between, flex-start);
	margin-bottom: 30px;
}

.trade_form_stats_pair_account_pair {
	@include flexbox(flex-start, center);

	i {
		font-size: 18px;
		line-height: 16px;
		color: var(--color-blue);
		margin: 0 6px 0 3px;
	}
}

.trade_form_stats_pair_account_pair_name {
	font-size: 16px;
	font-weight: bold;
	margin: 0 25px 0 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: auto;

	> span {
		font-weight: normal;
		font-size: 13px;
		color: var(--color-secondary);
		width: max-content;
	}

	@include max-w(1500px) {
		margin: 0 0 0 5px;
	}

	@include max-w(1100px) {
		color: var(--color-blue);
		font-size: 14px;

		i {
			color: var(--color-blue);
		}
	}

	i {
		font-size: 10px;
		line-height: 10px;
		margin: 0 10px 0 5px;
	}
}

.trade_form_stats_pair_account_account {
	font-weight: bold;
	color: var(--color-yellow);
	font-size: 12px;
	text-transform: uppercase;
	padding: 1px 4px;
}

.trade_form_stats_actions {
	display: grid;
	grid-template: auto / 1fr 1fr 1fr;
	grid-gap: 6px;
	margin-bottom: 40px;
	width: 100%;

	@include max-w(1440px) {
		grid-template: auto / 1fr 1fr;
	}

	@include max-w(1080px) {
		margin-bottom: 20px;
	}

	@include max-w(600px) {
		grid-template: auto / repeat(3, 1fr);
	}

	> button {
		outline: none;
		height: 30px;
		min-height: 30px;
		padding: 6px;
		border-radius: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: var(--tab-button-backround);
		color: var(--tab-button-color);
		text-decoration: none;

		&:hover {
			background: var(--tab-button-background-hover);
		}
	}
}

.trade_form_stats_risk {
	@include flexbox(space-between, flex-start);
	width: 100%;
	margin-bottom: 15px;
}

.trade_form_stats_risk_group {
	@include flexbox(flex-start, flex-start, column);
}

.trade_form_stats_risk_balance {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;

	&:not(:last-child) {
		margin-bottom: 20px;
	}

	span {
		font-size: 12px;
		line-height: 14px;
		color: var(--color-secondary);

		&:first-child {
			margin-bottom: 6px;
		}
	}

	b {
		font-weight: bold;
		font-size: 16px;
		line-height: 19px;
		color: var(--color-primary);
	}
}

.trade_form_stats_risk_rate {
	@include flexbox(flex-start, center, column);
}

.trade_form_action_types {
	width: 100%;
	display: grid;
	grid-template: auto / repeat(3, 1fr);
	margin: 14px 0 16px;
	grid-gap: 10px;

	> label {
		display: inline-flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		padding-left: 0 !important;

		&:before {
			position: relative;
			top: -2px;
		}

		&:after {
			top: 4px;
			left: calc(50% - 6px);
		}

		i {
			top: 5px;
			left: calc(50% - 6px);
		}
	}

	@include max-w(md) {
		margin: 8px 0;
	}
}

.trade_form_margin_wallet_balance {
	@include flexbox(flex-start, flex-end, column);
	position: relative;
	padding-bottom: 25px;

	span {
		font-size: 12px;
		line-height: 120%;
		text-align: right;
		color: var(--color-secondary);
	}
}

.trade_form_margin_wallet_action {
	@include flexbox(flex-start, center);
	color: var(--color-secondary);
	font-size: 12px;
	position: absolute;
	bottom: 2px;
	right: 0;
	width: max-content;

	span {
		font-size: 14px;
		margin-left: 8px;
		color: var(--color-primary);
	}
}

.trade_form_loans {
	@include flexbox(flex-start, flex-start, column);
	padding: 20px 0;
	border-top: 1px solid var(--divider-border);
	width: 100%;

	> span {
		display: block;
		font-weight: normal;
		font-size: 12px;
		line-height: 14px;
		color: var(--color-secondary);
		margin-bottom: 12px;
		padding: 0 26px;
	}
}

.trade_form_loans_list {
	@include flexbox(flex-start, flex-start, column);
	width: 100%;
	max-height: 200px;
	overflow: overlay;
}

.trade_form_loans_list_item {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: normal;
	font-size: 14px;
	line-height: 19px;
	color: var(--color-secondary);
	padding: 4px 26px;
	min-height: 26px;

	&:nth-child(even) {
		background: var(--stripped-normal);
	}
}

// POSITIONS
.positions_widget {
	padding-bottom: 0;
}

.positions_header {
	@include flexbox(space-between, center);
	width: 100%;
	height: 35px;
	border-bottom: 1px solid;
	border-color: transparent;
	margin-bottom: 0;
	padding-right: 20px;

	&.expanded {
		border-color: var(--divider-border);
		margin-bottom: 10px;
	}
}

.positions_tab {
	font-size: 12px;
	border-bottom: none;
	pointer-events: none;

	> span {
		color: var(--color-blue);
	}
}

.positions_expand_button {
	@include flexbox(center, center);
	padding: 0;
	margin: 0;
	position: absolute;
	left: 15px;

	i {
		font-size: 18px;
		color: var(--color-blue);
	}
}

// ORDERS HISTORY

.orders_history_widget {
	height: auto;
	padding-bottom: 0;
	overflow: hidden;
}

.orders_history_container {
	@include flexbox(space-between, center);
	width: 100%;
	height: 35px;
	min-height: 35px;
	margin-bottom: 8px;
	position: relative;

	&:before {
		content: "";
		width: 100%;
		height: 1px;
		background: var(--table-header-border-color);
		left: 0;
		bottom: 2px;
		position: absolute;
		z-index: 1;
	}
}

.orders_history_widget_tabs {
	min-width: 540px;
	border-bottom: none;

	&:before {
		display: none;
	}

	@include max-w(md) {
		min-width: 450px;
	}
}

.orders_history_widget_tab {
	flex: 0;
	font-size: 12px;
	text-transform: uppercase;
	min-width: 180px;
	width: 180px;

	@include max-w(md) {
		min-width: 150px;
		width: 150px;
	}
}

.orders_history_whole_history {
	@include flexbox(flex-end, center);
	min-height: 42px;
	padding: 8px 24px;
	margin-top: auto;
	width: 100%;

	i {
		font-size: 14px;
		margin-left: 10px;
		color: var(--color-blue);
	}
}

.orders_history_table_content {
	min-height: 375px;
	max-height: 375px;

	@include max-h(1080px) {
		min-height: 350px;
		max-height: 350px;
	}

	@include max-h(900px) {
		min-height: 250px;
		max-height: 250px;
	}
}

.orders_history_row,
.orders_history_row_trades_row,
.positions_table_row {
	height: 39px;
	max-height: 39px;
}

.orders_history_opened_orders_row_date {
	display: flex;
	flex-wrap: wrap;
}

.orders_history_open_orders_row_cancel_button {
	width: 18px;
	height: 18px;
	@include flexbox(center, center);
	padding: 5px;

	i {
		font-size: 14px;
	}

	svg {
		width: 18px;
	}
}

.orders_history_edit_order_button {
	@include flexbox(center, center);

	i {
		color: var(--color-blue);
		font-size: 16px;
		margin-left: 4px;
	}
}

.order_history_edit_actions {
	@include flexbox(center, center);
	margin-left: 9px;

	> button > i {
		color: var(--color-blue);
	}
}

.order_history_edit_input {
	background-color: var(--card-background-color);
	border: 1px solid var(--input-normal);
	height: 29px;
	color: var(--color-primary);
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	border-radius: 5px;
	padding: 3px 8px;
	outline: none;
	-moz-appearance: textfield;

	&:active,
	&:focus {
		border-color: var(--color-blue);
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&.price {
		width: 90px;
	}

	&.amount {
		width: 70px;
	}
}

.orders_history_options {
	@include flexbox(flex-end, center);
	padding-right: 15px;
	padding-bottom: 3px;
	width: 50%;

	&.disabled {
		> button {
			opacity: 0.8;
			pointer-events: none;
		}
	}

	button i {
		font-size: 22px;
	}

	button:hover i {
		color: var(--color-blue);
	}
}

.orders_history_options_checkbox_container {
	width: max-content;
	margin-right: 40px;

	@include max-w(xxl) {
		margin-right: 10px;

		label {
			font-size: 12px;
		}
	}

	@include max-w(md) {
		label {
			width: max-content;

			span {
				transform: translateY(3px);
				margin-right: 5px;
				border-radius: 3px;
				flex: 0 0 17px;
				height: 17px;
				width: 17px;
			}
		}
	}
}

.isolated_row {
	height: unset;
}

.order_book_total_value {
	margin-right: 10px;

	> span {
		color: var(--color-secondary);
	}
}
