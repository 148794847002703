.top_pairs_mini_chart {
	width: 100px;
	height: 40px;
	border-radius: 10px;
	position: relative;
	display: table;
	margin: 0 auto;
	pointer-events: none;
}

.top_pairs_mini_chart_canvas {
	z-index: 10;
	width: 100% !important;
	height: 100% !important;
}
