@import "src/assets/styles/common/mixins";

.Hero {
	width: 100%;
	position: relative;
	padding: 60px 0 30px;
	z-index: 0;

	@include max-w(md) {
		padding: 40px 0;
	}
	@include max-w(xs) {
		padding: 20px 0;
	}

	.Action {
		> a:not(:last-child) {
			margin-right: 20px;
		}

		@include max-w(xs) {
			> a:not(:last-child) {
				margin-right: 0;
				margin-bottom: 10px;
			}
		}
	}
}

.MobileApp {
	@include flexbox(space-between, flex-start);
	padding: 100px 0 0;
	width: 100%;

	@include max-w(xxxl) {
		padding-top: 0;
	}

	@include max-w(lg) {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}

	.Content {
		width: 100%;

		.Action {
			@include flexbox(flex-start, flex-start, column);
			width: 100%;
		}
	}
}

.MobileLinks {
	display: grid;
	grid-template: auto / 1fr 1fr;
	grid-gap: 15px;
	margin-top: 15px;
	align-items: center;
	justify-items: center;
	width: 100%;

	@include max-w(md) {
		grid-template: auto / 1fr;
		margin-bottom: 20px;
	}

	> a {
		@include flexbox(center, center);
	}
}

.MobileAppDownload {
	@include flexbox(flex-start, flex-start);
	margin-top: 20px;
	width: 100%;

	@include max-w(lg) {
		margin-bottom: 20px;
		@include flexbox(center, center);
	}
}

.MobileAppDownloadBtn {
	@include flexbox(center, center);
	width: 100%;
}

.QRCode {
	@include flexbox(center, center);
	border-radius: 12px;
	padding: 8px;
	margin-right: 15px;
	background-color: #fff;
}

.QRCodeDesc {
	@include flexbox(flex-start, flex-start, column);

	> span {
		&:nth-child(1) {
			font-size: 22px;
			color: #fff;
		}
		&:nth-child(2) {
			font-size: 16px;
			color: #ddd;
		}
	}
}

.Phone {
	position: relative;

	img {
		width: 100%;
		height: auto;
		max-width: 320px;
	}
}

.UahTitle {
	font-family: "Montserrat", sans-serif;
	font-style: normal;
	margin-bottom: 10px;

	@include font-size(
		(
			xxs: 32px,
			xxl: 40px,
		),
		40px
	);
}

.UahSubtitle {
	font-family: "Montserrat", sans-serif;
	font-weight: 500;

	@include font-size(
		(
			xxs: 28px,
			xxl: 32px,
		),
		32px
	);
}

.UahText {
	color: #e5e5e5;

	@include font-size(
		(
			xxs: 14px,
			xxl: 16px,
		),
		16px
	);
}

.Uah {
	@include flexbox(center, center);
	width: 100%;

	a {
		width: 100%;
	}

	.UahCard {
		z-index: 0;
		margin: auto;
		width: 100%;
		background: #161645bf;
		border-radius: 16px;
		background: url(../../assets/images/home_page/uah-card-bg.png);
		position: relative;
		background-size: cover;

		@include max-w(xs) {
			background: none;
		}
		.UahContent {
			padding: 70px 120px;
			@include max-w(xxxl) {
				padding: 70px 60px;
			}
			@include max-w(xl) {
				padding: 50px 25px;
			}
			@include max-w(md) {
				padding: 50px 200px 50px 25px;
			}
			@include max-w(xs) {
				padding: 0;
			}
		}
		.UahEllipseContainer {
			position: absolute;
			overflow: hidden;
			width: 100%;
			height: 100%;
			border-radius: 16px;
			z-index: -1;
			@include max-w(xs) {
				display: none;
				overflow: visible;
			}
		}
		.UahJarContainer {
			position: absolute;
			z-index: -1;
			top: 10%;
			right: 0;
			@include max-w(xxxl) {
				right: 0;
			}
			@include max-w(xl) {
				right: 0;
				top: 9%;
				transform: scale(0.6);
			}
			@include max-w(md) {
				right: -15%;
				transform: scale(0.6);
			}
			.UahJar {
				position: relative;
				.Heart {
					position: absolute;
					top: -32%;
					right: 27%;
				}
			}
		}
	}
}

.Title {
	margin: 0;
}

.Offer {
	padding-top: 80px;
	width: 100%;
	.Title {
		font-size: 48px;
		font-weight: 700;
		line-height: 59px;
		text-align: left;
		white-space: nowrap;
		margin-bottom: 40px;

		@include max-w(xs) {
			text-align: center;
			font-size: 35px;
		}
	}
	.HomePageOfferCards {
		display: grid;
		grid-template: auto / 1fr 1fr;
		grid-gap: 20px;

		@include max-w(md) {
			grid-template: auto / 1fr;
		}

		.HomePageOfferCard {
			position: relative;
			background: #12172ec7;
			border-radius: 8px;
			z-index: 0;
			flex: 40% 1;

			&:not(:last-child) {
				@include max-w(xs) {
					margin-bottom: 75px;
				}
			}

			@include max-w(md) {
				flex: 100%;
			}
			.HomePageOfferCardEllipseContainer {
				position: absolute;
				overflow: hidden;
				width: 100%;
				height: 100%;
				border-radius: 8px;
				background: transparent;
				z-index: -1;
			}
			.Image {
				position: absolute;
				top: -5%;
				right: -2%;
				@include max-w(xs) {
					top: -30%;
					right: 0;
					left: 0;
					transform: scale(0.6);
				}
			}
			.Content {
				padding: 220px 65px 20px 40px;
				@include max-w(xs) {
					padding: 120px 20px 20px 20px;
				}
				color: #ffffff;
				.CardTitle {
					font-style: normal;
					font-weight: 700;
					font-size: 24px;
					line-height: 32px;
					padding-bottom: 10px;
				}
				.CardText {
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 24px;
				}
			}
		}
	}
}

.Faq {
	padding: 100px 0;
	width: 100%;
	@include max-w(md) {
		padding: 60px 0;
	}
	.FaqCard {
		@include flexbox(stretch, center, column);
		padding: 60px 60px 40px 60px;
		@include max-w(xs) {
			padding: 20px;
		}
		margin: auto;
		width: 100%;
		background: #12172ec7;
		border-radius: 16px;
		position: relative;
		.FaqCardHeader {
			@include flexbox();
			@include max-w(lg) {
				flex-direction: column;
			}
			@include max-w(xs) {
				flex-wrap: wrap;
			}
			width: 100%;

			.FaqCardHeaderContent {
				width: 100%;
				.FaqCardTitle {
					font-style: normal;
					font-weight: 700;
					font-size: 32px;
					line-height: 39px;
					display: flex;
					padding-bottom: 20px;
				}
				.FaqCardText {
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 24px;
					color: #ffffff;
				}
			}
			.FaqLink {
				margin-left: 15px;

				@include max-w(lg) {
					margin-top: 100px;
					margin-left: auto;
				}

				@include max-w(xs) {
					width: 100%;
				}
			}
		}
		.FaqCardContent {
			padding-top: 63px;
			width: 100%;
		}
	}
}

.Accordion {
	background: unset;
	border: none;
	color: #fff;

	div,
	span {
		background-color: transparent !important;
	}
}

.AccordionRow {
	background: unset;
	border: none;
	border-radius: 0;
	border-bottom: 1px solid;
	border-color: #272e6b !important;
	color: #fff !important;

	div,
	span {
		background-color: transparent !important;
		color: #fff !important;
	}

	> div {
		&:nth-child(1) {
			padding: 0 !important;
		}
		&:nth-child(2) {
			border-bottom: none !important;
		}
		&:nth-child(2) > div {
			padding: 14px 0 !important;
		}
	}

	&:hover {
		background-color: unset;
	}
}

.AccordionContent {
	> div {
		color: var(--color-white);

		@include max-w(md) {
			padding: 15px;
		}
	}
}

.Background {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	pointer-events: none;
	.BackgroundContainer {
		width: 100%;
		height: 100%;
		position: relative;
	}

	@include min-w(2560px) {
		width: 150%;
		left: -25%;
	}

	@include max-w(md) {
		width: 200%;
		left: -70%;
	}
}

.BackgroundImage {
	z-index: -1;
	position: absolute;
	margin: auto;
	left: 0;
	right: 0;
	&.Hero {
		padding-top: 260px;
		@include max-w(xs) {
			padding-top: 350px;
		}
	}
	&.Mobile {
		padding-top: 450px;
		@include max-w(xxxl) {
			padding-top: 300px;
		}
	}
	&.Uah {
		padding-top: 300px;
		@include max-w(xs) {
			padding-top: 550px;
		}
	}
	&.Faq {
		padding: 0;
		@include max-w(xs) {
			padding-top: 360px;
		}
	}
}

.Coins {
	position: absolute;
	top: 50px;
	right: 5%;
	height: 11%;
	width: 38%;
	@include max-w(xxxl) {
		right: 5%;
		height: 11%;
		width: 40%;
	}
	@include max-w(xl) {
		right: 3%;
		height: 12%;
		width: 44%;
	}
	@include max-w(lg) {
		right: -3%;
		height: 10%;
		width: 56%;
		transform: scale(0.8);
	}
	@include max-w(md) {
		right: -5%;
		height: 12%;
		width: 63%;
		transform: scale(0.6);
	}
	@include max-w(xs) {
		right: -20%;
		height: 28%;
		width: 109%;
		transform: scale(0.6);
		top: -10%;
		z-index: -1;
	}
	.Container {
		width: 100%;
		height: 100%;
		position: relative;
		.Coin {
			position: absolute;
			&.Btc {
				left: 4%;
				bottom: 10%;
			}
			&.BtcOutlined {
				left: 35%;
				top: 10%;
			}
			&.Eth {
				left: 8%;
				top: 30%;
			}
			&.EthOutlined {
				right: 24%;
				top: 44%;
			}
			&.BtcOpaque {
				right: 38%;
				top: 30%;
			}
			&.EthOpaque {
				left: 25%;
				top: 40%;
			}
		}
	}
}

.Content {
	@include flexbox(flex-start, flex-start, column);
	@include max-w(xl) {
		width: auto;
	}
	&.Mobile {
		max-width: 720px;
		padding-top: 20px;
		padding-right: 30px;

		@include max-w(lg) {
			max-width: 100%;
			padding: 20px;
			@include flexbox(center, center, column);

			.Title,
			.Text {
				text-align: center;
			}
		}
	}
	@include max-w(xs) {
		&.Hero {
			.Title {
				font-style: normal;
				font-weight: 200;
				font-size: 45px;
				line-height: 48px;
			}
		}
		&.Mobile {
			padding: 0;
		}
	}
	.Title {
		font-size: 52px;
		line-height: 58px;
		@include max-w(xl) {
			font-size: 40px;
			line-height: 44px;
		}
		b {
			font-weight: 800;
			position: relative;
			.Outline {
				margin: auto;
				position: absolute;
				top: -10px;
				bottom: 0;
				right: 0;
				left: -52px;
				width: 300px;
				@include max-w(xl) {
					margin: auto;
					position: absolute;
					top: -10px;
					bottom: 0;
					right: 0;
					left: -8%;
					width: 260px;
				}
				@include max-w(md) {
					margin: auto;
					position: absolute;
					top: -3px;
					bottom: 0;
					right: 0;
					left: -8%;
					width: 200px;
				}
			}
		}
		@include max-w(md) {
			font-size: 30px;
			line-height: 42px;
		}
	}
	.Subtitle {
		padding-top: 10px;
		font-size: 32px;
		font-weight: 400;
		@include max-w(md) {
			font-size: 20px;
		}
	}
	.Text {
		padding: 25px 5px;
		width: 517px;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		text-align: left;
		@include max-w(xl) {
			width: 400px;
		}
		@include max-w(md) {
			width: auto;
		}
	}
	.ImageContent {
		margin: auto;
		padding: 10px 0;
	}
	.Action {
		@include flexbox();

		@include max-w(xs) {
			flex-wrap: wrap;
			width: 100%;
		}
		&.Uah {
			width: auto;
			@include max-w(xs) {
				width: 100%;
			}
		}
		.Link {
			width: 100%;
		}
	}
}

.Button {
	border: 1px solid white;
	border-radius: 8px;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	padding: 15px 70px;
	@include max-w(xs) {
		border: none;
		width: 100%;
		padding: 15px;
	}
	text-align: center;
	color: #ffffff;
	&.Gradient {
		position: relative;
		border: none;
		overflow: hidden;
		&::after,
		&::before {
			content: "";
			z-index: -1;
			position: absolute;
			inset: 0;
			border-radius: 8px;
			padding: 3px;
			background: linear-gradient(
				to bottom,
				rgba(255, 44, 44, 1) 28%,
				rgba(206, 27, 27, 1) 48%,
				rgba(105, 26, 198, 1) 73%
			);
		}

		&::after {
			@include transition-long(width);
			width: 0;
			left: -10%;
			transform: skewX(15deg);
			opacity: 0.8;
		}

		&::before {
			-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
			-webkit-mask-composite: xor;
			mask-composite: exclude;
			pointer-events: none;
			background-size: 110% 110%;
		}
		&:hover {
			animation: gradient 1000ms infinite ease-in-out;

			&::after {
				width: 120%;
			}
		}
	}
}

@keyframes gradient {
	0% {
		filter: drop-shadow(0 0 0.75rem rgba(255, 44, 44, 1));
	}
	25% {
		filter: drop-shadow(0 0 0.75rem rgba(206, 27, 27, 1));
	}
	50% {
		filter: drop-shadow(0 0 0.75rem rgba(105, 26, 198, 1));
	}
	75% {
		filter: drop-shadow(0 0 0.75rem rgba(206, 27, 27, 1));
	}
	100% {
		filter: drop-shadow(0 0 0.75rem rgba(255, 44, 44, 1));
	}
}

.Ellipse {
	position: absolute;
	left: -30%;
	top: 200px;
	height: auto;
	width: auto;
	z-index: -1;
	border-radius: 50%;
}

.Ellipse2 {
	position: absolute;
	z-index: -1;
	height: auto;
	width: auto;
	border-radius: 50%;
	top: 41%;
}

.Ellipse3 {
	position: absolute;
	z-index: -1;
	height: auto;
	width: auto;
	border-radius: 50%;
	right: -30%;
	top: 50px;
}

.Ellipse4 {
	position: absolute;
	height: 358px;
	width: 468px;
	border-radius: 50%;
	top: -25%;
	left: -9%;
	background: rgba(26, 48, 249, 0.42);
	filter: blur(150px);
	-webkit-filter: blur(150px);
	-moz-filter: blur(150px);
}

.Ellipse5 {
	position: absolute;
	height: 407px;
	width: 396px;
	border-radius: 50%;
	bottom: -27%;
	right: -5%;
	background: #a90db7;
	filter: blur(250px);
}

.Ellipse6 {
	height: 248px;
	width: 266px;
	border-radius: 50%;
	bottom: -10%;
	left: -10%;
	background: #cb00dd;
	filter: blur(200px);
	position: absolute;
}

.Ellipse7 {
	position: absolute;
	height: 426px;
	width: 457px;
	border-radius: 50%;
	bottom: -35%;
	right: -21%;
	background: rgba(26, 48, 249, 0.42);
	filter: blur(200px);
}

// TOP PAIRS
.top_pairs_section {
	margin-bottom: 40px;
}

.top_pairs_mini_chart {
	width: 100px;
	height: 50px;
	border-radius: 10px;
	position: relative;
	display: table;
	margin: 0 auto;
	pointer-events: none;
}

.top_pairs_mini_chart_canvas {
	z-index: 10;
	width: 100% !important;
	height: 100% !important;
}

.top_pairs_container {
	@include flexbox(flex-start, flex-start, column);
	width: 100%;
	border-radius: 18px;
	padding: 0 6px;
	background-color: transparent;
	max-width: get-breakpoint-value(xl);
	z-index: 2;
	margin-top: 20px;

	.top_pairs_table_header {
		padding: 0 14px;

		> div {
			color: #fff !important;
			font-size: 14px;
		}
	}

	.top_pairs_table_rows {
		flex-direction: column-reverse;

		.top_pairs_table_row {
			padding: 5px 10px;
			height: 70px;
			border-radius: 8px;
			min-height: 70px;
			border-bottom: none;

			> a {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}

			&:hover {
				background-color: #111041 !important;
			}

			.top_pairs_table_row_link {
				@include transition-long(all);

				padding: 8px 24px;
				font-size: 14px;
				line-height: 16px;
				text-align: center;
				border: 2px solid #28704f;
				border-radius: 5px;
				color: #3bd991;

				@include max-w(lg) {
					width: 100%;
					padding: 10px;
					display: block;
				}
			}

			.top_pairs_table_row_pair {
				@include flexbox(flex-start, center);

				img {
					user-select: none;
					border-radius: 50%;
					margin: 0 12px 0 0;
				}

				span {
					color: #fff;
					font-weight: bold;
					font-size: 16px;
					line-height: 19px;
				}

				i {
					color: #fff;
					margin: 0 8px 2px 0;
				}
			}

			.top_pairs_table_row_diff {
				font-size: 16px;
				line-height: 19px;
				height: auto;

				i {
					font-size: 24px;
				}

				&.red,
				&.red i {
					color: #ff5f66;
				}

				&.green,
				&.green i {
					color: #53b987;
				}
			}

			.top_pairs_table_row_price {
				color: #fff;
				font-size: 16px;
			}
		}
	}

	@include max-w(lg) {
		padding: 20px;

		.top_pairs_table_header {
			display: none;
		}

		.top_pairs_table_rows {
			display: grid;
			grid-gap: 10px;
			grid-template: auto / 1fr 1fr;
			padding: 10px;

			.top_pairs_table_row {
				@include flexbox(center, center, column);
				height: auto;

				> div {
					width: 100%;
					@include flexbox(center, center);

					&:nth-child(4),
					&:nth-child(6),
					&:nth-child(7) {
						display: none;
					}
				}

				&:not(:first-child) {
					border-bottom: none;
				}

				&:hover {
					background-color: transparent !important;
				}

				.top_pairs_table_row_pair {
					flex-direction: column;
					justify-content: center;

					i {
						margin: 0 0 10px 0;
					}

					img {
						margin: 0 0 12px 0;
						height: 40px;
						width: 40px;
					}
				}
			}
		}
	}

	@include max-w(md) {
		padding: 10px;
	}

	@include max-w(xs) {
		margin-top: 0;
		padding: 0;
	}

	@include max-w(380px) {
		.top_pairs_table_rows {
			grid-template: auto / 1fr;
		}
	}
}

// NEWS
.news_section {
}

.news_item {
	border-radius: 8px;
	overflow: hidden;
	@include flexbox(center, center);

	img,
	span {
		border-radius: 8px;
		margin: 0 auto;
	}

	> div {
		@include flexbox(center, center);
	}

	picture {
		width: 100%;
	}

	img {
		width: calc(100% - 10px);
	}

	picture,
	img {
		height: 135px;
		object-fit: cover;
	}

	@include max-w(xxl) {
		picture,
		img {
			height: 182px;
		}
	}

	@include max-w(md) {
		picture,
		img {
			height: 140px;
		}
	}

	@include max-w(xs) {
		picture,
		img {
			height: auto;
		}
	}
}

.news_slider_container {
	max-width: get-breakpoint-value(xl);
	width: 100%;
	margin: 55px auto 0;
	position: relative;
	border-radius: 8px;
	overflow: hidden;
	z-index: 2;

	.slick_arrow {
		@include flexbox(center, center);

		position: absolute;
		width: 36px;
		height: 36px;
		z-index: 9;
		cursor: pointer;
		border-radius: 50%;
		background: #00000073;
		top: calc(50% - 18px);

		i {
			font-size: 12px;
			color: #fff;
		}
	}

	.slick_arrow_next {
		right: 12px;
	}

	.slick_arrow_prev {
		left: 12px;
	}

	.slick_disabled {
		display: none;
	}

	@include max-w(md) {
		padding: 0 10px;
		margin: 30px auto 0;
	}
}

.news_slider_skeleton {
	display: grid;
	grid-template: auto / repeat(4, 1fr);
	grid-gap: 10px;
	width: 100%;
	max-height: 135px;
	overflow: hidden;
	padding: 0 5px;

	@include max-w(xxl) {
		grid-template: auto / repeat(3, 1fr);
	}

	@include max-w(lg) {
		grid-template: auto / repeat(2, 1fr);
	}

	@include max-w(xs) {
		grid-template: auto / 1fr;
	}
}
