@import "src/assets/styles/common/mixins";

.pie_circle_loader {
	width: 270px !important;
	height: 270px !important;
	margin-left: 30px;
	margin-right: 24px !important;
}

.graph_marker {
	min-width: 10px;
	height: 10px;
	border-radius: 5px;
	margin-right: 8px !important;
}

.list_container {
	display: flex;
	flex-direction: column;
	min-height: 20px;
	width: 100%;
}

.pie_data_list_container {
	flex: 1;
	width: 100%;
}

.pie_wrapper {
	display: flex;
	flex-direction: row;
	align-items: flex-start;

	@include max-w(md) {
		flex-direction: column;
		align-items: center;
	}
}

.skeleton_1 {
	margin-bottom: 20px !important;
	margin-top: 16px !important;
}

.skeleton_2 {
	display: flex;
}

.title {
	margin-bottom: 20px !important;
	font-weight: 500;
	font-size: 16px;
	font-variant: all-small-caps;
}

.legend {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-left: 20px;

	@include max-w(md) {
		margin-left: 0;
		margin-top: 20px;
	}
}

.list_element {
	display: flex;
	align-items: center;
	margin-bottom: 8px !important;
	justify-content: space-between;
}

.custom_tooltip {
	border-radius: 6px;
	padding: 6px 10px;
	height: auto;
	color: var(--tooltip-color);
	background-color: var(--tooltip-background);

	> p {
		text-transform: capitalize;
		margin-block-start: 0;
		margin-block-end: 0;
		font-size: 12px;
	}
}
