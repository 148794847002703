@import "src/assets/styles/common/mixins";

.header_content {
	button {
		margin-top: 10px;
		white-space: nowrap;
		min-width: 240px;
	}

	@include max-w(md) {
		a,
		button {
			width: 100%;
		}
	}
}

.content {
	max-width: get-breakpoint-value(xl);
	width: 100%;
	margin: 0 auto;
	padding: 10px 10px 20px;
}

.donations {
	@include card();
	margin: 16px 0;
	display: grid;
	grid-template: auto / 1fr 1fr;
	border-radius: 8px;

	section {
		@include flexbox(flex-start, flex-start, column);
		width: 100%;

		&:first-child {
			border-right: 1px solid var(--card-border-color);
		}
	}

	@include max-w(lg) {
		grid-template: auto / 1fr;

		section:first-child {
			border-right: none;
		}
	}
}

.donations_header {
	@include flexbox(flex-start, flex-start);
	padding: 15px;
	width: 100%;
	background-color: var(--card-header-background);

	i {
		font-size: 18px;
		margin: 4px 10px 0 0;

		&[class~="ai-star_filled"] {
			color: var(--color-gold);
		}
	}

	h2 {
		font-weight: bold;
		color: var(--color-primary);
		margin: 0 0 5px;
		font-size: 24px;
	}

	span {
		font-size: 16px;
		color: var(--color-secondary);
	}
}

.projects {
	width: 100%;
	display: grid;
	grid-template: auto / 1fr;
	grid-gap: 16px;

	&.history_mode {
		margin-bottom: 20px;
	}
}

.project {
	@include card();
	display: grid;
	grid-template: auto / 1fr 2fr;
	height: 212px;

	@include max-w(lg) {
		grid-template: auto / 1fr;
		height: auto;
	}
}

.project_img {
	@include flexbox(center, center);
	padding: 20px;
	background-color: var(--card-header-background);
	position: relative;
}

.disable_img {
	filter: grayscale(100);
}

.project_status {
	position: absolute;
	top: 15px;
	right: 15px;
}

.project_content {
	@include flexbox(space-between, flex-start);
	padding: 18px;

	@include max-w(lg) {
		justify-content: flex-start;
		flex-direction: column;
	}
}

.project_body {
	@include flexbox(flex-start, flex-start, column);
	padding-right: 20px;
	width: 100%;
	height: 100%;

	h3 {
		font-weight: bold;
		font-size: 24px;
		color: var(--color-primary);
		margin: 0 0 10px;
	}

	a:hover h3 {
		color: var(--color-coral);
	}

	span {
		color: var(--color-secondary);
		font-size: 16px;
	}
}

.project_progress {
	@include flexbox(flex-start, flex-start, column);
	max-width: 475px;
	width: 100%;
	margin-top: auto;

	@include max-w(lg) {
		max-width: unset;
		margin: 14px 0;
	}
}

.project_progress_amount {
	@include flexbox(flex-start, flex-end);
	width: 100%;
	margin-bottom: 6px;

	span {
		@include flexbox(flex-start, center);
		color: var(--color-secondary);

		i {
			font-size: 20px;
			margin-right: 4px;
		}

		b {
			font-size: 24px;
			font-weight: bold;
			color: var(--color-primary);
		}

		&:last-child {
			margin-left: auto;
		}
	}
}

.project_meta {
	@include flexbox(space-between, flex-end, column);
	height: 100%;

	a {
		@include flexbox(flex-start, center);
		color: var(--color-coral);

		i {
			font-size: 10px;
			margin-left: 6px;
			color: var(--color-coral);
		}
	}

	@include max-w(lg) {
		flex-direction: row;
		width: 100%;
		align-items: center;
		margin: 14px 0;
	}
}

.project_meta_donates {
	@include flexbox(flex-start, center);
	font-size: 14px;
	color: var(--color-secondary);

	i {
		font-size: 12px;
		margin-right: 8px;
	}

	> span {
		color: var(--color-primary);
	}
}

.alc_coin {
	@include flexbox(flex-start, center);
	@include card();

	padding: 22px 45px;
	position: relative;
	margin: 16px 0;

	i {
		position: absolute;
		top: 10px;
		left: 10px;
		font-size: 20px;
	}

	@include max-w(lg) {
		flex-direction: column;
	}
}

.alc_coin_header {
	@include flexbox(flex-start, center);

	@include max-w(lg) {
		margin-bottom: 20px;
	}

	@include max-w(md) {
		flex-direction: column-reverse;
		text-align: left;
		width: 100%;
	}

	img {
		@include transition-long(filter);
		height: auto;
		width: auto;
		margin: 0 60px 0 40px;

		@include max-w(md) {
			margin: 0 0 20px;
		}
	}

	&:hover {
		img {
			filter: drop-shadow(0 0 0.75rem var(--color-coral));
		}
	}

	h3 {
		font-size: 24px;
		font-weight: bold;
		margin: 10px 0;
	}
}

.alc_coin_info {
	display: inline-block;
	flex: 1;
	text-align: left;
	color: var(--color-secondary);
	max-width: 680px;
	font-size: 16px;

	a {
		display: inline-block;
		color: #ff542e;
	}
}

.how_it_works {
	@include flexbox(space-between, center);
	@include card();
	position: relative;
	padding: 24px 45px;

	@include max-w(lg) {
		flex-direction: column;
	}
}

.how_it_works_title {
	@include flexbox(flex-start, flex-start, column);
	padding-right: 30px;

	h3 {
		font-size: 24px;
		font-weight: bold;
		margin: 10px 0;
	}

	span {
		color: var(--color-secondary);
		font-size: 16px;
	}

	@include max-w(lg) {
		margin-bottom: 24px;
		padding-right: 0;
	}
}

.how_it_works_list {
	display: grid;
	grid-template: auto / repeat(3, 1fr);
	align-items: flex-start;
	grid-gap: 40px;

	@include max-w(xs) {
		grid-template: auto / 1fr;
	}
}

.how_it_works_list_item {
	@include flexbox(center, center, column);

	img {
		margin-bottom: 10px;
		height: 40px;
		width: auto;
	}

	b {
		color: var(--color-primary);
		font-weight: bold;
		margin-bottom: 10px;
		font-size: 18px;
		line-height: 18px;
	}

	span {
		font-size: 16px;
		color: var(--color-secondary);
		text-align: center;
	}
}

.see_history_link {
	color: var(--color-coral);
	padding-left: 10px;
	margin-bottom: 20px;

	i {
		margin-left: 5px;
		font-size: 10px;
		color: var(--color-coral);
	}
}
