@import "src/assets/styles/common/mixins";
.container {
	display: flex;
	padding: 0 10px;
	flex: 1 0 auto;
	flex-direction: column;
	color: #fff;
	background-color: #161645;
	overflow: hidden;
	position: relative;

	img {
		pointer-events: none;
	}
}

.limiter {
	margin: 0 auto;
	max-width: 1170px;
	width: 100%;
	z-index: 0;

	@include max-w(xxxl) {
		padding: 0 20px;
	}

	@include max-w(xl) {
		padding: 0 10px;
	}

	@include min-w(2560px) {
		position: relative;
	}
}

.landing {
	background-color: #161645 !important;
	border: none;
}
