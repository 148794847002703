@import "src/assets/styles/common/mixins";

.container {
	@include card();
	max-width: 550px;
	margin: 30px auto;
}

.outlined_card_panel_content {
	padding: 45px 80px;

	@include max-w(xs) {
		padding: 55px 30px 30px;
	}

	> div:first-child {
		margin-bottom: 24px;
		text-align: center;
	}
}

.outlined_card_panel_btn_group {
	display: flex;
	flex-direction: column;
	text-align: center;
	margin-top: 47px;

	> *:not(:last-child) {
		margin-bottom: 15px;
	}

	&.preloader-wrapper {
		vertical-align: middle;
	}
}

.header {
	font-weight: bold;
	text-align: center;
	margin: 0 0 25px 0;
	line-height: 130%;

	@include max-w(xs) {
		margin-bottom: 15px;
	}
}

.outlined_card_panel_form_body {
	> div:not(:last-child) {
		margin-bottom: 16px;
	}

	margin-bottom: 45px;
}
