@import "src/assets/styles/common/mixins";

.container {
	display: flex;
	align-items: center;
}

.icon {
	font-size: 16px;
	margin: 0 8px 2px 0;
}

.label {
	font-size: 14px;
}
