@import "src/assets/styles/common/mixins";

.page_container {
	@include page-content-outer("md");
	margin-top: 5px;
}

.card {
	@include card();
	@include flexbox(flex-start, center, column);
	margin: 0;
	padding: 50px 150px;
	width: 100%;

	@include max-w(xs) {
		padding: 24px;
	}

	> span {
		@include card-subtitle();
	}
}

.card_title {
	@include card-title();
	text-align: center;
}

.created_transfer_container {
	display: grid;
	grid-template: auto / 1fr;
	justify-items: center;
	width: 100%;
	grid-gap: 20px;
	margin-top: 30px;

	img {
		margin-bottom: 30px;

		@include max-w(xs) {
			width: 100%;
			height: auto;
		}
	}
}

.created_transfer_info {
	display: grid;
	grid-template: auto / 1fr;
	grid-gap: 14px;
	width: 100%;
}

.created_transfer_info_item {
	display: grid;
	grid-template: auto / 1fr 1fr;
	grid-gap: 10px;
	width: 100%;
	align-items: center;

	span {
		&:nth-child(1) {
			color: var(--color-secondary);
			font-size: 14px;
			line-height: 16px;
			text-align: left;
		}
		&:nth-child(2) {
			color: var(--color-primary);
			font-size: 16px;
			line-height: 19px;
			text-align: right;
			word-break: break-word;
		}
	}
}

.form_container {
	width: 100%;
	display: grid;
	grid-template: auto / 1fr;
	grid-gap: 24px;
	margin: 34px 0;
}

.security_code_container {
	display: grid;
	grid-template: auto / 2fr 1fr;
	width: 100%;
	grid-gap: 8px;
	align-items: start;
}
