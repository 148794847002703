@import "assets/styles/common/mixins";

.wrapper {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	z-index: 999;
	@include flexbox(center, center);
	background: #20242e;
}

.container {
	@include flexbox(space-between, center);
	padding: 20px;
	max-width: get-breakpoint-value(xxl);
	width: 100%;

	> span {
		font-size: 16px;
		line-height: 1.3;
		color: #fff;
		text-align: left;
	}

	> img {
		margin-right: 20px;
	}

	@include max-w(md) {
		flex-direction: column;

		> img {
			display: none;
		}
	}

	.controls {
		@include flexbox();

		margin-left: 85px;

		@include max-w(md) {
			margin-left: 0;
			margin-top: 20px;
			width: 100%;

			> button {
				width: 100%;
			}
		}
	}
}
