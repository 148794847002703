@import "src/assets/styles/common/mixins";

.page_container {
	@include page-content-outer("md");
}

.card {
	@include card();
	@include flexbox(flex-start, center, column);
	margin: 0;
	padding: 50px 150px;
	width: 100%;

	@include max-w(xs) {
		padding: 24px;
	}
}

.card_title {
	@include card-title();
	text-align: center;
}

.accepted_transfer_container {
	display: grid;
	grid-template: auto / 1fr;
	justify-items: center;
	width: 100%;
	grid-gap: 20px;
	margin-top: 30px;

	img {
		margin-bottom: 30px;

		@include max-w(xs) {
			width: 100%;
			// height: auto;
		}
	}
}

.card_subtitle {
	@include card-subtitle();
	text-align: justify;
}

.transfer_info {
	display: grid;
	grid-template: auto / 1fr;
	width: 100%;
	grid-gap: 20px;
	justify-items: center;
	margin: 30px 0;
}

.transfer_info_item {
	span {
		font-size: 14px;
		line-height: 16px;
	}
}

.form_container {
	width: 100%;
	display: grid;
	grid-template: auto / 1fr;
	grid-gap: 24px;
	margin: 24px 0 14px;
}
