@import "src/assets/styles/common/mixins";

.header_wrapper {
	width: 100%;
}

.header {
	width: 100%;
	max-width: get-breakpoint-value(xxxl);
	margin: 0 auto;
	@include flexbox(flex-start, center, column);
	border: 1px solid var(--card-border-color);
	border-radius: 8px;
	overflow: hidden;
	background-color: var(--card-background-color);
}

.header_content {
	@include flexbox(center, center);
	padding: 40px;
	width: 100%;
	position: relative;

	&:not(:only-child) {
		border-bottom: 1px solid var(--card-border-color);
	}

	> div {
		width: 100%;
		z-index: 2;
	}

	&.masked {
		&:before {
			content: "";
			width: 100%;
			height: 100%;
			top: 0;
			z-index: 1;
			left: 0;
			transform: scale(-1);
			position: absolute;
			pointer-events: none;
			background-image: url(../../../assets/images/page/header-mask.svg);
			background-color: var(--card-background-color);
			background-position: left center;
			background-size: contain;
			background-repeat: no-repeat;
		}
	}
}

.header_nav {
	width: 100%;
	min-height: 80px;
	position: relative;
	@include flexbox(flex-start, flex-start, column);
}

.header_nav_tabs {
	@include flexbox(flex-start, center);

	width: 100%;
}

.header_nav_page {
	height: 42px;
	min-height: 42px;
	@include flexbox(flex-start, flex-end);

	> a {
		height: 42px;
		min-height: 42px;
		@include flexbox(center, center);
	}
}
