@import "assets/styles/common/mixins";

.container {
	display: grid;
	align-items: center;
	grid-template: auto / 1fr;
	grid-gap: 6px;
	grid-template-areas:
		"margin-label"
		"margin-indicator"
		"margin-value";

	&.inline {
		grid-template: auto / 1fr 1fr;
		grid-gap: 6px;
		grid-template-areas:
			"margin-indicator margin-label"
			"margin-indicator margin-value";
	}

	> span {
		font-size: 12px;
		line-height: 14px;
		text-align: center;
		font-style: normal;
		color: var(--color-secondary);
		grid-area: margin-label;
	}

	> b {
		font-weight: bold;
		font-size: 18px;
		line-height: 21px;
		text-align: center;
		color: var(--color-primary);
		margin-top: 4px;
		grid-area: margin-value;
	}
}

.indicator {
	@include flexbox(center, center);
	position: relative;
	width: 78px;
	height: 39px;
	grid-area: margin-indicator;
}

.umbrella {
	width: 78px;
	height: 39px;
}

.arrow {
	height: 20px;
	width: 8px;
	position: absolute;
	bottom: 1px;
	transition: 1s ease-in-out;
	transform-origin: bottom center;
	// transform: rotate(${(props) => props.deg}deg);
}
