@import "src/assets/styles/common/mixins";

.container {
	@include flexbox(center, center);
	min-height: calc(100vh - 800px);
	padding: 40px;

	@include max-w(md) {
		padding: 20px;
	}
}

.content {
	@include flexbox(flex-start, center, column);
	max-width: 840px;

	a,
	button {
		width: 100%;
		max-width: 340px;
	}

	> h1 {
		font-size: 36px;
		font-weight: bold;
		margin: 0 0 6px;
		color: var(--color-primary);
	}

	> img {
		width: 100%;
		display: block;
		margin: 40px auto;
		min-height: 250px;
	}
}
