@import "src/assets/styles/common/mixins";

.card {
	padding: 0;
}

.card_header {
	@include card-header();
	width: 100%;
	min-height: 60px;
	padding: 24px;
	border-bottom: 1px solid var(--table-header-border-color);
	background-color: var(--card-background-color);
	border-radius: 8px 8px 0 0;

	> a,
	> span {
		&:last-child {
			cursor: pointer;
			margin-left: 25px;
			color: var(--color-blue);

			i {
				font-size: 16px;
				margin-left: 15px;
			}
		}
	}

	@include max-w(xxl) {
		min-height: 55px;
	}

	@include max-w(md) {
		padding: 16px;
		flex-wrap: wrap;

		> a,
		> span {
			&:last-child {
				margin-left: 0;
			}
		}
	}
}

.card_title {
	@include card-title();
	@include flexbox(flext-start, center);

	margin: 0;

	i {
		font-size: 24px;
		margin-right: 10px;
	}

	a {
		cursor: pointer;
		margin-left: auto;
		color: var(--color-blue);

		i {
			font-size: 16px;
			margin-left: 15px;
		}
	}

	@include max-w(xxl) {
		i {
			font-size: 24px;
			margin-right: 15px;
		}
	}

	@include max-w(md) {
		width: 100%;

		i {
			font-size: 24px;
			margin-right: 10px;
		}

		a {
			font-size: 14px;
			margin-left: 15px;
		}
	}
}

.current_level {
	@include flexbox(flex-start, flex-start, column);
	width: 100%;
	padding: 10px;
}

.current_level_description {
	font-weight: normal;
	font-size: 16px;
	line-height: 23px;

	color: var(--color-secondary);
	margin-bottom: 15px;
}

.next_level {
	@include flexbox(space-between, center);
	width: 100%;

	@include max-w(md) {
		@include flexbox(flex-start, flex-start, column);

		a {
			margin-top: 8px;
		}
	}
}

.verification_levels {
	margin-top: 0px;
}
