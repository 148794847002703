@import "assets/styles/common/mixins";

.phone_input_wrapper {
	@include transition(border-color, color);

	position: relative;
	width: 100%;

	input {
		border: 1px solid var(--input-normal);
		width: 100%;
		font-size: 14px;
		background: none;
		box-sizing: border-box;
		padding: 5px 10px 4px 10px;
		overflow-y: hidden;
		outline: none;
		border-radius: 12px;
		height: 47px;
		transition: border-color 200ms ease-in-out;
		color: var(--color-primary);

		&:-webkit-autofill,
		&:-webkit-autofill:focus {
			transition: background-color 600000s 0s, color 600000s 0s;
		}

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&:-webkit-autofill {
			caret-color: var(--color-caret);
		}

		&:hover {
			border-color: var(--input-hover);
		}
	}

	&.active {
		input {
			border-color: var(--input-focused);
		}
	}

	&.disabled {
		input {
			border-color: var(--input-disabled);
			color: var(--input-disabled);
		}
	}
	&.error {
		input {
			border-color: var(--input-error);
			color: var(--input-error);
		}
	}
}
