ul.custom-slick-dots {
	li {
		position: relative;
		display: inline-block;
		width: 14px;
		height: 14px;
		margin: 0 3.5%;
		padding: 0;
		cursor: pointer;

		button {
			border-radius: 50%;
			z-index: 10;
			background-color: #ffffff;
			width: 14px;
			height: 14px;
			cursor: pointer;
			&:before {
				opacity: 0;
				color: unset;
			}
		}
	}

	@keyframes right-spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(180deg);
		}
	}

	@keyframes left-spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes close-wrapper {
		100% {
			clip: rect(auto, auto, auto, auto);
		}
	}

	li.slick-active {
		button {
			background-color: #4d8ff1 !important;
			&:before {
				opacity: 0;
				color: unset;
			}
		}
	}

	li:not(.slick-active):hover {
		button {
			transform: scale(2);
		}
	}
}

/* Slider */
.slick-slider {
	position: relative;

	display: block;
	box-sizing: border-box;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.slick-list {
	position: relative;

	display: block;
	overflow: hidden;

	margin: 0;
	padding: 0;

	div {
		outline: none;
	}
}

.slick-list:focus {
	outline: none;
}

.slick-list.dragging {
	cursor: pointer;
	cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	top: 0;
	left: 0;

	display: block;
	margin-left: auto;
	margin-right: auto;
}

.slick-track:before,
.slick-track:after {
	display: table;

	content: "";
}

.slick-track:after {
	clear: both;
}

.slick-loading .slick-track {
	visibility: hidden;
}

.slick-slide {
	display: none;
	float: left;

	height: 100%;
	min-height: 1px;
}

[dir="rtl"] .slick-slide {
	float: right;
}

.slick-slide img {
	display: block;
}

.slick-slide.slick-loading img {
	display: none;
}

.slick-slide.dragging img {
	pointer-events: none;
}

.slick-initialized .slick-slide {
	display: block;
}

.slick-loading .slick-slide {
	visibility: hidden;
}

.slick-vertical .slick-slide {
	display: block;

	height: auto;

	border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
	display: none;
}
/* Icons */
@font-face {
	font-family: "slick";
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

/* Arrows */
.slick-prev,
.slick-next {
	font-size: 0;
	line-height: 0;

	position: absolute;
	top: 50%;

	display: block;

	width: 20px;
	height: 20px;
	padding: 0;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);

	cursor: pointer;

	color: transparent;
	border: none;
	outline: none;
	background: transparent;
}

.slick-dots {
	position: absolute;
	bottom: -25px;
	display: block;
	width: 100%;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;
}

.slick-dots li {
	position: relative;

	display: inline-block;

	width: 20px;
	height: 20px;
	margin: 0 5px;
	padding: 0;

	cursor: pointer;
}

.slick-dots li button {
	font-size: 0;
	line-height: 0;

	display: block;

	width: 20px;
	height: 20px;
	padding: 5px;

	cursor: pointer;

	color: transparent;
	border: 0;
	outline: none;
	background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
	outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
	opacity: 1;
}

.slick-dots li button:before {
	font-family: "slick";
	font-size: 6px;
	line-height: 20px;

	position: absolute;
	top: 0;
	left: 0;

	width: 20px;
	height: 20px;

	content: "•";
	text-align: center;

	opacity: 0.25;
	color: black;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
	opacity: 0.75;
	color: black;
}
