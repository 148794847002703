@import "src/assets/styles/common/mixins";

.container {
	@include card(false);
	width: 100%;
	display: block;
	padding: 0;

	@include max-w(xl) {
		padding: 24px;
	}
}

.card_title {
	@include card-title();
}

.header {
	@include flexbox(space-between, center);
	padding: 24px 34px;

	& > .card_title {
		margin: 0;
		font-size: 20px;
		@include max-w(xxl) {
			font-size: 18px;
		}
	}

	@include max-w(xl) {
		padding: 0 0 15px 0;
	}
}

.badge_list {
	@include flexbox(flex-start, center);
	flex-wrap: wrap;

	span {
		margin: 0 4px 4px 0;
	}

	@include max-w(xl) {
		@include flexbox(flex-end, center);
	}
}

.row_with_padding {
	.row {
		padding: 0 30px;
		justify-content: space-between;
	}
}

.card_mobile {
	@include cardMobile();
}

.card_mobile_header {
	@include cardMobileHeader();
}

.card_mobile_action {
	@include cardMobileAction();

	&.active {
		@include cardMobileAction(true);
	}
}

.card_mobile_content {
	@include cardMobileContent();
}

.card_mobile_content_group {
	@include cardMobileContentGroup();
}

.card_mobile_content_hidden {
	@include cardMobileContentHidden();
}
