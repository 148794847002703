@import "src/assets/styles/common/mixins";

.card {
	@include card();

	@include max-w(md) {
		padding-bottom: 0;
	}
}

.card_header {
	@include card-header();
	width: 100%;
	min-height: 60px;
	padding: 24px;
	border-radius: 8px 8px 0 0;
	border-bottom: 1px solid var(--table-header-border-color);
	background-color: var(--card-background-color);

	> a,
	> span {
		&:last-child {
			cursor: pointer;
			margin-left: 25px;
			color: var(--color-blue);

			i {
				font-size: 16px;
				margin-left: 15px;
			}
		}
	}
}

.card_title {
	@include card-title();
	@include flexbox(flex-start, center);
	margin: 0;

	i {
		font-size: 24px;
		margin-right: 10px;
	}

	a {
		cursor: pointer;
		margin-left: auto;
		color: var(--color-blue);

		i {
			font-size: 16px;
			margin-left: 15px;
		}
	}

	@include max-w(xxl) {
		i {
			font-size: 24px;
			margin-right: 15px;
		}
	}

	@include max-w(md) {
		width: 100%;

		i {
			font-size: 24px;
			margin-right: 10px;
		}

		a {
			i {
				font-size: 14px;
				margin-left: 15px;
			}
		}
	}
}

.stat_list {
	@include flexbox(space-between, center);
	width: 100%;
	padding: 25px 24px;

	@include max-w(md) {
		@include flexbox(flex-start, flex-start, column);
	}

	@include max-w(xxs) {
		padding: 16px;
	}
}

.stat_list_item {
	@include flexbox(flex-start, flex-start, column);

	@include max-w(md) {
		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}
}

.stat_list_item_value {
	@include flexbox(flex-start, center);
	font-weight: normal;
	font-size: 24px;
	line-height: 28px;
	color: var(--color-primary);
	margin-bottom: 15px;

	@include max-w(md) {
		font-size: 20px;
		line-height: 25px;
		margin-bottom: 8px;
	}
}

.stat_list_item_label {
	font-weight: normal;
	font-size: 16px;
	line-height: 23px;
	color: var(--color-secondary);

	@include max-w(md) {
		font-size: 14px;
		line-height: 19px;
	}
}

.stat_info_sack {
	@include flexbox(space-between, center);
	padding: 0 28px 0 32px;
	width: 100%;
	height: 46px;
	border-radius: 0 0 8px 8px;
	background-color: var(--color-orange);

	span {
		color: var(--color-white);
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
	}

	a {
		i {
			font-size: 16px;
			color: var(--color-white);
		}
	}

	@include max-w(xxl) {
		padding: 0 28px 0 24px;
	}

	@include max-w(md) {
		position: relative;
		height: auto;
		padding: 6px 24px;

		span {
			font-size: 14px;
		}
	}
}

.stats_link_button {
	width: 100%;
}
