.container {
	width: auto;
}

.full_width {
	width: 100%;
}

.full_height {
	height: 100%;
}
