@import "src/assets/styles/common/mixins";

.mobile_container {
	@include card();
	@include flexbox(stretch, stretch, column);
	flex: 1 0 auto;
	position: relative;
	margin: 0;
	padding: 0 0 80px;
	height: calc(100vh - 55px);
}

.mobile_nav_tabs {
	@include flexbox(stretch, center);
	min-height: 70px;
	height: 70px;
	position: fixed;
	width: 100%;
	bottom: 0;
	left: 0;
	z-index: 98;
	padding: 3px 0 6px;
	min-width: 320px;
	background: var(--card-background-color);
}

.mobile_nav_tab {
	@include flexbox(center, center, column);
	border: none;
	flex: 1;
	height: 100%;
	line-height: 1.3;
	font-weight: normal;
	font-size: 12px;
	color: var(--color-secondary);

	i {
		font-size: 22px;
		margin-bottom: 4px;
		color: var(--icon-primary);

		&[class~="ai-trade-candle-01"] {
			transform: scale(1.35);
		}
	}

	&.active {
		color: var(--color-blue);

		i {
			color: var(--color-blue);
		}
	}
}

.orders_item {
	padding: 10px 0;
	margin: 0 15px;

	&:not(:last-child) {
		border-bottom: 1px solid var(--divider-border);
	}
}

.orders_grid {
	display: grid;
	align-items: center;
	grid-template: auto / 3fr 3fr 4fr;
	grid-row-gap: 3px;
}

.width_restriction {
	margin-right: 15px;
	min-width: 70px;
}

.order_row {
	@include flexbox(space-between, center);
}

.order_row_inner {
	@include flexbox(stretch, center);
}

.orders_pair_details {
	color: var(--color-primary);
	font-size: 14px;
	font-weight: 600;
}

.delete_order_row {
	@include flexbox(flex-end, center);

	> i {
		font-size: 12px;
	}
}

.orders_filled_row {
	@include flexbox(flex-end, center);
}

.orders_amount_green {
	font-weight: normal;
	font-size: 14px;
	color: var(--color-green);
}

.orders_amount_red {
	font-weight: normal;
	font-size: 14px;
	color: var(--color-red);
}

.orders_prop_text {
	font-weight: normal;
	font-size: 14px;
	color: var(--color-secondary);

	&.right {
		text-align: right;
	}
}

.orders_digits_text {
	font-weight: normal;
	font-size: 14px;
	color: var(--color-primary);
}

.order_text_filled {
	color: var(--color-primary);
	font-weight: normal;
	font-size: 14px;
	margin-left: 7px;
}

.orders_date_text {
	font-weight: normal;
	font-size: 12px;
	line-height: 12px;
}

.remove_order_text {
	font-weight: normal;
	font-size: 14px;
	margin-right: 10px;
	color: var(--color-blue);
}

.orders_widget_text {
	font-weight: normal;
	font-size: 14px;
	color: var(--color-secondary);
}

.orders_trigger_value {
	color: #f79a29;
	font-weight: normal;
	font-size: 14px;
	background: rgba(247, 158, 27, 0.1);
	border-radius: 5px;
	margin-left: 5px;
	padding: 1px 5px;
}

.orders_trigger_data_row {
	@include flexbox();
}

.orders_inner_row_trigger {
	@include flexbox(space-between, center);
	margin-top: 5px;
}

.show_all_button {
	background: transparent;
	outline: none;
	border: none;
	cursor: pointer;

	&:active {
		outline: none;
	}

	&:focus {
		outline: none;
	}
}

.show_all_button_inner_wrapper {
	@include flexbox(stretch, center);
}

.show_all_button_text {
	font-size: 17px;
	font-weight: normal;
	color: var(--color-blue);
}

.show_all_button_row {
	@include flexbox(center, center);
	margin-top: 25px;
}

// Show all orders modal

.all_modal_wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--card-background-color);
	display: none;
	z-index: 9999;

	&.show {
		@include flexbox(stretch, stretch, column);
	}
}

.all_modal_close_button {
	outline: none;
	border: none;
	cursor: pointer;
}

.all_modal_service_row {
	@include flexbox(space-between);
	padding: 15px;
	height: max-content;
	width: 100%;
}

.all_modal_title {
	color: var(--color-primary);
	font-weight: 600;
	font-size: 17px;
}

.all_modal_void {
	width: 28px;
}

.all_modal_orders_wrapper {
	@include flexbox(stretch, stretch, column);
	overflow: auto;
}

.all_modal_filter_row {
	@include flexbox(space-between, center);
	padding: 20px 10px;
}

.history_tab_filter_row {
	@include flexbox(flex-end, center);
	padding: 20px 10px;
}

.history_tab_filter_title {
	font-weight: normal;
	font-size: 12px;
	color: var(--color-secondary);
	margin-right: 10px;
}

.active_orders_filter_text {
	font-weight: normal;
	font-size: 12px;
	color: var(--color-primary);
	margin: 0 5px;
}

.active_orders_filter_wrapper {
	@include flexbox(stretch, center);
}

.all_modal_cancel_all_text {
	font-weight: normal;
	font-size: 11px;
	margin-right: 7px;
	color: var(--color-secondary);
}

.all_modal_cancel_all_button {
	outline: none;
	border: none;
	cursor: pointer;
	padding: 0;
}

.cancel_order_icon {
	color: var(--color-blue);
}

.cancel_all_icon {
	color: var(--icon-primary);
	font-size: 20px;
}

.all_modal_back_icon {
	color: var(--color-blue);
	font-size: 15px;
}

.closed_order_done_icon {
	color: var(--color-green);
	font-size: 15px;
}

.orders_show_all_icon {
	color: var(--color-blue);
	font-size: 15px;
	margin-left: 15px;
}

// Active orders filter modal

.active_filters_overlay_fader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.4);
	display: none;
	z-index: 999;

	&.show {
		@include flexbox(flex-end, stretch, column);
	}
}

.active_filters_overlay_values_wrapper {
	@include flexbox(stretch, stretch, column);
	border-radius: 14px;
	margin: 8px;
	background: var(--history-orders-overlay-background-color);
}

.active_filters_overlay_back_button {
	border-radius: 14px;
	outline: none;
	border: none;
	margin: 0 8px 8px 8px;
	padding: 17px;
	font-weight: 600;
	font-size: 17px;
	background: var(--history-orders-overlay-background-color);
}

.active_filters_abort_button_text {
	font-weight: 600;
	font-size: 17px;
	color: var(--color-blue);
}

.filter_overlay_title {
	outline: none;
	border: none;
	padding: 14px 35px;
	border-bottom: var(--history-orders-overlay-button-border);
	font-weight: 600;
	font-size: 13px;
	text-align: center;
	color: var(--color-secondry);
}

.active_filter_value {
	@include flexbox(center, center);
	padding: 16.5px;
	border-bottom: var(--history-orders-overlay-button-border);

	&.last {
		border-bottom: none;
	}
}

.active_filter_value_text {
	font-weight: normal;
	font-size: 17px;
	color: var(--color-blue);
}

.active_filters_check_and_text {
	@include flexbox(center, center);
}

.active_orders_check_icon {
	color: var(--color-blue);
	font-size: 12px;
	margin-right: 10px;
}

.active_orders_filter_icon {
	color: var(--color-primary);
	font-size: 16px;
}

.active_orders_arrow_icon {
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 6px solid #6d7177;
}

// History orders filter modal

.history_filter_button {
	@include flexbox(center, center);
	outline: none;
	border: none;
	color: var(--color-primary);

	&:hover {
		color: var(--color-blue);
	}
}

.history_filters_content_wrapper {
	@include flexbox(stretch, stretch, column);
	padding: 15.5px 18px 26px 18px;
}

.history_filter_title {
	font-weight: normal;
	font-size: 15px;
	color: var(--color-secondary);
}

.history_filter_type_row {
	@include flexbox();
	margin-top: 16px;
}

.history_filter_canceled_row {
	@include flexbox(stretch, center);
	margin-top: 27px;
}

.history_filter_cancelled_text {
	font-weight: normal;
	font-size: 15px;
	color: var(--color-secondary);
	margin-left: 17px;
}

.history_filter_small_button {
	font-weight: normal;
	font-size: 12px;
	color: var(--color-blue);
	margin-right: 15px;
	background: var(--history-orders-overlay-time-filter-button-background);
	border-radius: 5px;
	padding: 0.5px 10px;

	&.active {
		color: var(--color-blue);
	}
}

.history_filter_time_row {
	@include flexbox();
	margin-bottom: 27px;
}

.history_filter_apply_button {
	font-weight: 600;
	font-size: 17px;
	color: var(--color-blue);
	padding: 11px;
	border-bottom: var(--history-orders-overlay-button-border);
	border-top: var(--history-orders-overlay-button-border);
}

.history_filter_reset_button {
	font-size: 17px;
	line-height: 22px;
	color: var(--color-blue);
	padding: 11px;
}

.history_filter_pair_row {
	@include flexbox();
	margin: 10px 0 20px 0;
	height: 36px;
}

.history_filter_pair_select {
	@include flexbox(flex-start, center);
	border: none;
	background: var(--input-background);
	border-radius: 10px;
	font-weight: 600;
	font-size: 14px;
	line-height: 16px;
	padding: 7px 10px;
	outline: none;
	width: 100%;
	color: var(--color-primary);
}

.history_pair_select_wrapper {
	@include flexbox();
	position: relative;
	width: 100%;
}

.history_filter_select_arrow_icon {
	color: var(--icon-primary);
	font-size: 12px;
	position: absolute;
	right: 8px;
	top: 50%;
	transform: translateY(-50%);
}

// ShowPairWindow

.show_pair_window_wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--card-background-color);
	display: none;
	z-index: 999;

	&.show {
		@include flexbox(stretch, stretch, column);
	}
}

.all_pairs_search_row {
	@include flexbox();
	margin: 10px 25px 25px 25px;
	position: relative;
}

.all_pairs_search_field {
	width: 100%;
	outline: none;
	border: none;
	background: var(--input-background);
	border-radius: 10px;
	font-weight: normal;
	font-size: 17px;
	color: var(--color-primary);
	padding: 8px 30px;
}

.all_pairs_items_title_row {
	@include flexbox();
	padding: 0 17px 6px 17px;
	border-bottom: 1px solid rgba(60, 60, 67, 0.29);
}

.all_pairs_title {
	font-weight: normal;
	font-size: 13px;
	color: var(--color-secondary);
}

.all_pairs_item_column {
	@include flexbox(stretch, stretch, column);
	padding-left: 15px;
	font-size: 17px;
	overflow: auto;
}

.all_pairs_item_row {
	@include flexbox(space-between, center);
	padding: 9px 0;
	border-bottom: 1px solid rgba(60, 60, 67, 0.29);
	outline: none;
	&:hover {
		background: var(--input-background);
	}

	&:active {
		border-bottom: 1px solid rgba(60, 60, 67, 0.29);
		outline: none;
	}

	&:focus {
		border-bottom: 1px solid rgba(60, 60, 67, 0.29);
		outline: none;
	}
}

.all_pairs_item_text {
	font-weight: normal;
	font-size: 17px;
	color: var(--color-primary);
}

.all_pairs_check_icon {
	color: var(--color-blue);
	font-size: 12px;
	font-weight: 600;
	margin-right: 16.4px;
}

.all_pairs_search_icon {
	color: var(--color-primary);
	font-size: 16px;
	position: absolute;
	left: 10px;
	top: 50%;
	transform: translateY(-50%);
}

.order_book_container {
	@include flexbox();
	width: 100%;
	flex: 1 0 auto;
	max-height: 220px;
	height: 220px;
	overflow: hidden;
	position: relative;

	&.full_height {
		max-height: unset;
		height: unset;
	}

	&.mini {
		max-height: 150px;
		height: 150px;
	}
}

.chart_container {
	@include flexbox();
	width: 100%;
	min-height: 400px;
	height: calc(100vh - 383px);
	position: relative;

	&:not(.active) {
		z-index: -99999;
		position: absolute;
		opacity: 0;
	}
}

.order_book_group {
	@include flexbox(stretch, stretch, column);
	flex: 1;
}

.trade_form_container {
	@include flexbox(stretch, stretch, column);
	flex: 1 0 auto;
	height: 100%;
	overflow: auto;
}

.balance_container {
	@include flexbox();
	color: var(--color-secondary);
	padding: 0 10px;
	font-size: 14px;
	margin: 5px 0;
	position: relative;

	i {
		font-size: 18px;
		line-height: 14px;
		margin-right: 5px;
		margin-bottom: 2px;
	}
}

.widget_tabs {
	margin: 0;
	width: 100%;
	padding: 0 12px;
}

.orders_history_widget_tab {
	max-width: unset;
}

.widget_tab {
	color: var(--color-secondary);

	&.active {
		color: var(--color-blue);
	}

	i {
		font-size: 12px;
		line-height: 12px;
		display: inline-block;
		margin-left: 15px;
		cursor: pointer;
		margin-right: -27px;
		color: var(--hint-icon-dark);
	}

	&.pressed i {
		color: var(--hint-icon-pressed);
	}
}

.balance_currency_container {
	@include flexbox(flex-start, center);
	flex: 1;
	color: var(--color-primary);

	&:nth-of-type(2) {
		justify-content: flex-end;
	}
}

.trade_container {
	display: grid;
	width: 100%;
	grid-gap: 10px;
	padding: 10px;
	grid-template: auto / 1fr 1fr;
}

.trade_button_container {
	padding: 0 10px;
	width: 100%;
}

.balance_currency {
	font-weight: bold;
	margin-right: 5px;
}

.unauthorized_text_block {
	padding: 10px;
	text-align: center;
	color: var(--color-primary);
}

.trades_chart_buttons {
	@include flexbox(flex-end, center);
	width: 100%;
	padding: 10px 16px;
}

.trades_chart_button {
	background: transparent;
	border: none;
	padding: 0;
	margin: 0;

	&:not(:first-child) {
		margin-left: 16px;
	}

	i {
		font-size: 20px;
		color: var(--color-primary);
	}

	&.active i {
		color: var(--color-blue);
	}
}

.buy_sell_buttons {
	display: grid;
	grid-template: auto / 1fr 1fr;
	grid-gap: 5px;
	width: 100%;
	padding: 12px;
}

.trade_form_advanced_container {
	@include flexbox(center, flex-end);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 605;
}

.trade_form_advanced_backdrop {
	background: var(--modal-overlay-color);
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}

.trade_form_advanced_content {
	background: var(--card-background-color);
	width: 100%;
	z-index: 2;
	overflow: auto;
	max-height: calc(100vh - 100px);
	border-radius: 8px 8px 0 0;
}

.trade_form_advanced_content_inner {
	padding: 0 10px;
}

.orders_trigger_text {
	margin-right: 8px;
	color: var(--color-secondary);
}
