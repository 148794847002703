@import "src/assets/styles/common/mixins";

.success_container {
	@include flexbox(center, center, column);
	padding: 60px;
	width: 100%;

	svg {
		margin-bottom: 20px;
	}

	span {
		font-weight: normal;
		font-size: 16px;
		line-height: 23px;
		text-align: center;
		color: var(--color-secondary);
		max-width: 400px;
	}
}
