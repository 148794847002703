@import "assets/styles/common/mixins";

.container {
	@include flexbox(center, center, column);
	padding: 20px;
	border-radius: 8px;
	margin: 10px auto;

	img {
		width: 100%;
		height: auto;
		max-width: 220px;
		margin-bottom: 16px;
	}

	span {
		font-size: 16px;
		color: var(--color-secondary);
	}
}
