@import "assets/styles/common/mixins";

.container {
	@include max-w(md) {
		padding: 24px;
	}
}

.form_input_group {
	width: 100%;
	display: grid;
	grid-template: auto / 1fr;
	grid-gap: 24px;
	margin: 34px 0;

	i {
		&[class~="ai-check_filled"] {
			color: var(--color-green);
		}
	}
}

.login_check_appender {
	margin-top: 1px;
	height: calc(100% - 2px);
	padding-left: 5px;
	padding-right: 11px;
	right: 2px;

	@include max-w(md) {
		background-color: var(--input-background);
		border-radius: 5px;
	}
}
