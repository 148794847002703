@import "src/assets/styles/common/mixins";

.wrapper {
	@include flexbox(center, center);
	width: 100%;
	padding: 20px;
}

.container {
	@include card(false);
	@include flexbox(flex-start, center, column);
	width: 100%;
	padding: 40px 20px;
	max-width: get-breakpoint-value(xs);

	> svg {
		margin-bottom: 30px;
	}

	> span {
		color: var(--color-secondary);
		margin-bottom: 30px;
		font-size: 16px;
	}

	@include max-w(md) {
		> a {
			width: 100%;
		}
	}
}
