.container {
	display: flex;
	justify-content: center;
	padding: 0px 1px;
	flex: 1 0;
	flex-basis: 0;
	width: 100%;
	height: 100%;
	position: relative;
}

.loader {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	z-index: 9;
	background-color: var(--card-background-color);
}

.chart_error {
	position: absolute;
	bottom: -8px;
	height: 50px;
	opacity: 0.9;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	z-index: 99;
	padding: 20px 10px;
	background-color: #333;
	border-radius: 0 0 8px 8px;

	> i {
		color: var(--color-yellow);
		font-size: 16px;
		margin-right: 8px;
	}

	> span {
		color: #fff;
		font-size: 14px;
	}
}
