@import "src/assets/styles/common/mixins";

.container {
	@include flexbox(flex-start, center);
	position: relative;
	margin: 5px 0;
	width: 100%;
	padding: 0 10px;

	@include max-w(xs) {
		@include flexbox(center, center, column);

		padding: 5px;
	}

	.label {
		font-weight: normal;
		font-size: 14px;
		line-height: 19px;
		text-align: right;
		color: var(--color-secondary);
		margin-right: 15px;

		@include max-w(xs) {
			margin-right: 0;
			margin-bottom: 12px;
		}
	}

	.page_list {
		@include flexbox(flex-start, center);

		.item {
			@include flexbox(center, center);

			font-size: 14px;
			line-height: 19px;
			text-align: center;
			color: var(--color-secondary);
			position: relative;
			width: 42px;
			min-width: 42px;
			height: 22px;
			min-height: 22px;
			cursor: pointer;
			border: none;
			background-color: unset;

			&:active,
			&:focus,
			&:hover {
				color: var(--color-blue);
			}

			&:before {
				content: "";
				position: absolute;
				width: calc(100% + 8px);
				height: calc(100% + 8px);
				top: -4px;
				left: -4px;
				border-radius: 5px;
				border: 1px solid var(--input-hover);
				opacity: 0;
				transform: scale(0.75);
				transition: 0.12s ease;
			}

			&.active::before {
				opacity: 1;
				transform: scale(1);
			}

			@include max-w(xs) {
				min-width: 30px;
				width: 30px;
			}
		}
	}

	.page_nav {
		@include flexbox(center, center);

		margin-left: 14px;

		@include max-w(xs) {
			margin-left: 0;
			margin-top: 12px;
		}
	}

	.page_nav_button {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 30px;
		height: 30px;
		min-width: 30px;
		min-height: 30px;
		background: var(--color-blue);
		border: none;

		&:focus {
			background: var(--color-blue);
			box-shadow: 0 0 0 2px var(--color-blue) 80;
		}

		&:disabled i {
			opacity: 0.25;
		}

		i {
			color: #fff;
			font-size: 14px;
			opacity: 1;
		}

		&:first-child {
			border-radius: 5px 0 0 5px;
		}

		&:last-child {
			border-radius: 0 5px 5px 0;
		}
	}
}
