@import "src/assets/styles/common/mixins";

.container {
	padding: 30px 20px;

	@include max-w(xxl) {
		padding: 20px 10px;
	}

	@include max-w(xs) {
		padding: 10px;
	}
}

.header_container {
	margin-bottom: 65px;

	@include make-responsive(
		"border-radius",
		(
			xs: 10px,
			xxxl: 20px,
		),
		20px
	);
	@include max-w(1500px) {
		background-size: cover;
		min-height: unset;
	}

	@include max-w(lg) {
		padding: 90px 30px;
	}

	@include max-w(xs) {
		margin-bottom: 85px;
		width: calc(100% + 20px);
		margin-left: -10px;
	}

	background-size: cover;
	background-image: url(../../assets/images/listing/header-background.png);
	background-position: top center;
	overflow: visible;
	background-color: var(--background-color);
}

.content {
	@include flexbox(flex-start, flex-start);

	position: relative;
	max-width: 1045px;
	width: 100%;
	margin: 0 auto;

	&.column {
		flex-direction: column;
	}

	&.footer {
		padding: 60px 0;

		@include max-w(xxl) {
			padding: 60px 30px;
		}

		.header_info {
			color: var(--color-primary);
		}

		.header_title {
			width: 100%;
		}

		.header_coins {
			top: -30px;

			@include max-w(lg) {
				right: 0;
				top: unset;
				bottom: -40px;
			}
		}

		.trc_icon {
			left: -10px;
			top: unset;
			bottom: 20px;
		}

		.btc_icon {
			width: 50px;
			top: 40px;
			right: -10px;
			bottom: unset;
		}

		.eth_icon {
			bottom: unset;
			right: 0;
			top: -55px;
		}
	}

	&.how_it_works {
		@include max-w(xxl) {
			padding: 0 15px;
		}

		padding: 0;

		.why_we_title {
			color: var(--color-black);
		}

		.why_we_desc {
			color: var(--color-black);
		}
	}
}

.header_info {
	@include flexbox(flex-start, flex-start, column);

	max-width: 620px;
	color: var(--color-white);

	@include max-w(lg) {
		min-width: unset;
	}

	@include max-w(xs) {
		align-items: center;
	}
}

.header_title {
	@include font-size(
		(
			xs: 32px,
			xxl: 42px,
			xxxl: 48px,
		),
		48px
	);

	@include make-responsive(
		"margin-bottom",
		(
			xs: 24px,
			xxxl: 40px,
		),
		40px
	);

	@include max-w(xs) {
		text-align: center;
	}

	line-height: 1.3;
	width: 80%;

	span {
		font-weight: 600;
	}
}

.header_desc {
	@include font-size(
		(
			xs: 16px,
			xxl: 18px,
			xxxl: 20px,
		),
		20xpx
	);

	@include make-responsive(
		"margin-bottom",
		(
			xs: 40px,
			xxxl: 60px,
		),
		60px
	);

	@include max-w(xs) {
		text-align: center;
	}

	line-height: 1.5;
	margin-bottom: 60px;
}

.header_button {
	width: 380px;

	@include max-w(xxl) {
		width: 280px;
	}

	@include max-w(xs) {
		width: 225px;
	}

	span {
		font-weight: 600;
	}

	&.white {
		background-color: var(--color-white) !important;
		color: var(--color-blue) !important;
	}
}

.header_coins {
	position: absolute;
	right: -125px;
	top: -60px;

	@include make-responsive(
		"width",
		(
			lg: 180px,
			xl: 300px,
			xxl: 500px,
			xxxl: 600px,
		),
		600px
	);

	@include max-w(xxl) {
		right: 0;
		top: -30px;
	}

	@include max-w(xl) {
		top: -30px;
	}

	@include max-w(lg) {
		right: -20px;
		top: unset;
		bottom: -105px;
	}
}

.eth_icon {
	position: absolute;
	bottom: -100px;
	right: -80px;
}

.trc_icon {
	position: absolute;
	left: -30px;
	top: -55px;
}

.btc_icon {
	position: absolute;
	bottom: -115px;
	right: 25px;
}

.why_we {
	@include max-w(xxl) {
		padding: 0 30px;
	}

	@include max-w(xs) {
		padding: 0 15px;
		align-items: center;

		.why_we_title {
			text-align: center;
		}

		.why_we_desc {
			text-align: center;
		}
	}
}

.why_we_title {
	@include font-size(
		(
			xs: 24px,
			xxl: 28px,
			xxxl: 32px,
		),
		32px
	);

	width: fit-content;
	position: relative;
	line-height: 1.3;
	color: var(--color-primary);
	margin-bottom: 30px;

	span {
		font-weight: 500;
	}
}

.why_we_desc {
	font-size: 16px;
	line-height: 19px;
	color: var(--color-primary);
	max-width: 450px;

	@include make-responsive(
		"margin-bottom",
		(
			xs: 50px,
			xxxl: 80px,
		),
		80px
	);
}

.why_we_adv {
	@include flexbox();

	flex-wrap: wrap;

	@include max-w(lg) {
		flex-direction: column;
		width: 100%;
	}
}

.why_we_item {
	@include flexbox();

	width: 50%;

	@include make-responsive(
		"margin-bottom",
		(
			xs: 40px,
			xxxl: 60px,
		),
		60px
	);

	@include max-w(lg) {
		width: 100%;
	}

	.icon {
		@include flexbox(center, center);

		width: 60px;
		height: 60px;
		border-radius: 8px;
		margin-right: 30px;
		flex-shrink: 0;
	}

	.meta {
		@include flexbox(flex-start, flex-start, column);
		@include font-size(
			(
				xs: 14px,
				xxxl: 16px,
			),
			16px
		);

		max-width: 325px;
		color: var(--color-primary);
		line-height: 1.3;

		strong {
			@include font-size(
				(
					xs: 16px,
					xxxl: 18px,
				),
				18px
			);

			font-weight: 700;
			margin-bottom: 15px;
		}
	}
}

.how_it_works {
	@include flexbox(stretch, stretch, column);

	background-color: #fafbff;
	border-radius: 20px;
	padding: 60px 0 100px;

	@include make-responsive(
		"padding-bottom",
		(
			xs: 24px,
			xxxl: 100px,
		),
		100px
	);
}

.how_it_works_icon {
	position: absolute;
	right: 0;
	top: 0;
	transform: translate(100%, -55%);
}

.how_it_works_list {
	@include flexbox(space-between);

	flex-wrap: wrap;

	@include max-w(md) {
		flex-direction: column;
	}

	@include max-w(xs) {
		align-items: center;
	}
}

.how_it_works_item {
	@include flexbox(stretch, stretch, column);
	@include make-responsive(
		"margin-bottom",
		(
			xs: 16px,
			xxxl: 20px,
		),
		20px
	);

	width: calc(50% - 15px);
	padding: 90px 100px;
	color: var(--color-white);
	font-weight: 500;

	@include min-w(get-breakpoint-value(md) + 1) {
		background: linear-gradient(108.69deg, #1b6cf4 6.44%, #37bcf6 99.44%);

		&:nth-child(2n) {
			background: linear-gradient(249.12deg, #1b6cf4 0%, #37bcf6 105.85%);
		}

		&:nth-child(1),
		&:nth-child(2) {
			border-top-right-radius: 100px;
			border-bottom-left-radius: 100px;
		}

		&:nth-child(3),
		&:nth-child(4) {
			border-top-left-radius: 100px;
			border-bottom-right-radius: 100px;
		}
	}

	@include max-w(md) {
		width: 100%;
		background: linear-gradient(108.69deg, #1b6cf4 6.44%, #37bcf6 99.44%);

		border-top-right-radius: 100px;
		border-bottom-left-radius: 100px;

		&:nth-child(2n) {
			border-radius: 100px 0 100px 0;
		}
	}

	@include max-w(xs) {
		padding: 50px 60px;
		max-width: 328px;
	}

	.number {
		@include font-size(
			(
				xs: 28px,
				xxxl: 46px,
			),
			46px
		);

		font-weight: 600;
	}

	.info {
		@include font-size(
			(
				xs: 18px,
				xxxl: 24px,
			),
			24px
		);

		line-height: 1.3;
	}
}
