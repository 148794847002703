@import "src/assets/styles/common/mixins";

.img_to_view_container {
  @include flexbox(center, center);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background: rgba(120, 120, 120, 0.35);
}

.img_to_view {
  max-width: 90%;
  max-height: 90%;
  width: auto;
  height: auto;
  cursor: zoom-out;
  border-radius: 5px;
  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.25);
}