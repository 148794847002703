@import "src/assets/styles/common/mixins";

.tag_close_icon {
	/*margin-right: 2px;*/
	margin-top: 1px;
	float: right;
	cursor: pointer;
	min-width: auto;
}

.tag {
	@include flexbox(stretch, center, row, inline-flex);
	white-space: nowrap;
	font-size: 12px;
	border-radius: 10px;
	font-weight: 500;
	min-height: 32px;
	height: 32px;
	padding: 2px 10px 2px 4px;
	border: 1px solid var(--icon-button-background-color-hover);
	line-height: 1.2;
}

.tag_score {
	color: #111;
	border-radius: 6px;
	font-size: 10px;
	padding: 4px 6px;
	font-weight: bold;
	text-align: center;
	margin-right: 8px !important;
	@include flexbox(center, center);
}

.tag_score_not_removable {
	padding-right: 3px;
}

.tag_presence {
	margin-left: 4px !important;
}
