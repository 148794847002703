@import "assets/styles/common/mixins";

.page_content_container {
	padding: 16px 24px;

	@include max-w(xl) {
		padding: 16px;
	}
}

.content_wrapper {
	@include flexbox(stretch, stretch, column);

	background-image: url("../../assets/images/competitions/page-mask.png");
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: 0 400px;
	width: 100%;
}

.container {
	display: grid;
	justify-items: self-start;
	align-items: self-start;
	align-content: flex-start;

	width: 100%;
	grid-gap: 16px;
}

.focus_content {
	display: grid;
	width: 100%;
	grid-template: auto / 1fr 1fr;
	grid-gap: 16px;

	@include max-w(xl) {
		grid-template-columns: 1fr;
		grid-template-rows: unset;
	}
}

.breadcrumbs_container {
	max-width: 980px;
	width: 100%;
	margin: 0 auto;
	padding: 10px;
}

.header_container {
	@include flexbox(flex-start, flex-start, column);

	width: 100%;
	min-height: 200px;
	background-color: var(--card-background-color);
	background-position: center;
	background-size: cover;
	position: relative;
	padding-bottom: 60px;
}

.header_content {
	display: grid;
	grid-template: auto / 1fr 310px;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	grid-gap: 120px;
	padding: 10px 20px;
	background-size: cover;
	min-height: unset;
	max-width: 1000px;
	margin: 0 auto;

	@include max-w(lg) {
		grid-template: auto / 1fr;
		grid-gap: 30px;
	}
}

.header_info {
	display: grid;
	justify-items: self-start;
	align-items: self-start;
	align-content: flex-start;
	grid-gap: 6px;
	padding: 10px 15px;

	@include max-w(lg) {
		padding: 20px;
	}
}

.header_title {
	font-weight: bold;
	font-size: 36px;
	line-height: 130%;
	color: #fff;

	margin: 0 0 5px;

	&.isDemo {
		color: #030303;
	}
}

.header_subtitle {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 23px;
	color: #fff;

	&.isDemo {
		color: #030303;
	}
}

.additional_info {
	@include flexbox(flex-end, flex-end, column);

	padding: 10px 15px;
	margin-left: auto;
	width: 100%;
	max-width: 310px;

	@include max-w(lg) {
		max-width: 100%;
	}
}

.demo_balance_container {
	@include flexbox(flex-start, flex-start, column);

	margin-bottom: 15px;

	> span {
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 14px;
		color: #030303;
		margin-bottom: 8px;
		padding-left: 6px;
	}
}

.demo_balance {
	@include flexbox(center, flex-start);

	> span {
		font-weight: bold;
		font-size: 24px;
		line-height: 28px;
		text-align: right;
		color: #030303;
	}
}

.demo_balance_currency {
	@include flexbox(center, center);

	position: relative;
	padding: 0 20px 0 0;
	margin-right: 10px;

	> i {
		font-size: 24px;
		color: #030303;
	}
}

.demo_balance_sign {
	@include flexbox(center, center);

	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 120%;
	text-align: center;
	color: #030303;
	border: 1px solid #030303;
	box-sizing: border-box;
	border-radius: 5px;
	width: 16px;
	height: 16px;
	min-width: 16px;
	min-height: 16px;
	cursor: pointer;
	margin-left: 5px;
	position: absolute;
	top: 0;
	right: 0;

	> div {
		display: none;
	}

	&:hover div,
	&:focus div {
		display: flex;
	}
}

.reset_demo_balance_container {
	border: 1px solid #fff;
	border-radius: 5px;
	display: grid;
	justify-items: self-start;
	align-items: self-start;
	align-content: flex-start;
	grid-gap: 10px;
	padding: 10px;
}

.reset_demo_balance_button {
	@include flexbox(flex-start, center);

	outline: none;
	padding: 0;

	> span {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 19px;
		color: var(--color-blue);
		text-decoration: underline;
	}

	> i {
		color: var(--color-blue);
		font-size: 14px;
		margin-right: 8px;
		transform: scale(2);
	}

	&:active,
	&:focus {
		outline: none;
	}
}

.reset_demo_balance_info {
	@include flexbox(flex-start, flex-start);

	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	color: #fff;

	i {
		font-size: 12px;
		margin-right: 8px;
		color: #fff;
	}
}

.login_message_container {
	border: 1px solid #fff;
	border-radius: 5px;
	color: #fff;
	padding: 10px 14px;

	> a {
		text-decoration: underline;
		color: #fff;
	}

	&.isDemo {
		color: #030303;

		> a {
			color: var(--color-blue);
		}
	}
}

.status_list {
	@include flexbox(flex-start, center);

	> div:not(:last-child) {
		margin-right: 10px;
	}
}

.status_badge {
	@include flexbox(center, center);

	color: #fff;
	background: rgba(255, 255, 255, 0.1);
	padding: 4px 16px;
	border-radius: 5px;
	font-size: 12px;
	line-height: 120%;

	&.disabled {
		background: rgba(60, 60, 60, 0.1);
	}
}

.terminal_snack {
	width: 100%;
	padding: 8px;
	background: #008041;
	border-radius: 0;
	display: block;
	text-align: center;
	color: #fff;
	position: absolute;
	bottom: 0;
	left: 0;

	&.isDemo {
		background: var(--color-demo);
	}

	i {
		color: #fff !important;
		font-size: 16px;
		margin-right: 6px;
		vertical-align: middle;
		margin-bottom: 2px;
	}

	a {
		color: #fff;
		text-decoration: underline;
	}
}

.results_container {
	@include card();

	padding: 7px;
	margin: 0;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;

	&.isDemo {
		grid-template-columns: repeat(3, 1fr);
	}

	@include max-w(xl) {
		grid-template-columns: 1fr;
	}

	@include max-w(xs) {
		grid-gap: 12px;
		padding: 10px;
	}
}

.column_group {
	@include flexbox(space-between, center);

	border-right: 1px solid var(--divider-border);

	&:last-child {
		border-right: none;
	}

	@include max-w(xl) {
		@include flexbox(flex-start, flex-start, column);

		border-right: none;
	}

	@include max-w(xs) {
		align-items: center;
	}
}

.column {
	@include flexbox(center, flex-start, column);

	position: relative;
	min-height: 72px;
	padding: 16px 10px 16px 10px;

	&.hasPadding {
		padding-left: 72px;
	}

	&:last-child {
		padding-right: 10px;

		&.hasPadding {
			padding: 72px;
		}
	}

	> span {
		color: var(--color-secondary);
		font-size: 16px;
		line-height: 23px;

		&.primary {
			margin-bottom: 5px;
			font-size: 24px;
			line-height: 28px;
			color: var(--color-primary);
		}
	}

	i-cup {
		position: absolute;
		color: var(--color-demo);
		top: 15px;
		left: 20px;
		font-size: 30px;
	}

	@include max-w(xl) {
		padding: 10px;

		&:last-child {
			padding: 10px;
		}

		&[class~="ai-cup"] {
			position: relative;
			top: unset;
			left: unset;
			margin: 0 10px 5px 0;
		}
	}

	@include max-w(xs) {
		align-items: center;

		&[class~="ai-cup"] {
			position: relative;
			top: unset;
			left: unset;
			margin: 0 0 5px 0;
		}
	}
}

.position {
	font-style: normal;
	font-weight: bold;
	color: var(--color-secondary);
	font-size: 16px;
	line-height: 23px;

	span {
		color: var(--color-demo);
		font-size: 24px;
	}
}

.my_position_container {
	width: 100%;
	display: grid;
	grid-template: auto / 1fr auto;
	grid-gap: 10px;
	align-items: center;
	justify-content: center;
	border-right: 1px solid var(--divider-border);
	padding: 16px 24px 16px 10px;

	i {
		color: var(--color-demo) !important;
		font-size: 24px;
		margin-right: 14px;
	}

	> span:nth-child(1) {
		font-weight: normal;
		font-size: 16px;
		line-height: 23px;
		color: var(--color-secondary);
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
}

.total_my_deals_container {
	width: 100%;
	display: grid;
	grid-template: auto / 1fr auto;
	grid-gap: 10px;
	align-items: center;
	justify-content: center;
	padding: 16px 24px;

	> span {
		&:nth-child(1) {
			font-weight: normal;
			font-size: 16px;
			line-height: 23px;
			color: var(--color-secondary);
		}

		&:nth-child(2) {
			font-size: 24px;
			line-height: 28px;
			text-align: right;
			color: var(--color-primary);
		}
	}
}

.share_link_container {
	@include flexbox(flex-start, flex-start, column);

	color: var(--color-secondary);
	width: 100%;
	padding: 0;
	margin: 0;

	.header {
		width: 100%;
		padding: 18px 20px;
		display: grid;
		grid-template: auto / 1fr 1fr;
		grid-gap: 20px;
		justify-content: center;
		align-items: center;
		border-bottom: 1px solid var(--divider-border);

		@include max-w(xs) {
			grid-template: auto / 1fr;
			justify-items: self-start;
			align-items: self-start;
			align-content: flex-start;
		}
	}

	.title {
		@include flexbox(flex-start, center);

		font-weight: bold;
		font-size: 16px;
		line-height: 19px;
		color: var(--color-primary);
		margin: 0;
		padding: 0;

		i {
			font-size: 18px;
			margin-right: 16px;
			color: var(--color-demo);
		}

		@include max-w(xxl) {
			font-size: 20px;
		}
	}

	.share_container {
		@include flexbox(flex-end, center);

		> button {
			margin-left: 10px;
		}
	}

	.social_list {
		@include flexbox(flex-end, center);

		a {
			margin: 0 12px;

			i {
				color: var(--icon-primary);
				font-size: 22px;
				transition: 0.1s ease;
				margin: 0;
			}

			&:hover {
				i {
					color: var(--color-blue);
				}
			}
		}
	}

	.content {
		@include flexbox(flex-end, center);

		min-height: 40px;
		width: 100%;
		padding: 5px 18px;

		a {
			color: var(--color-secondary);
			font-size: 14px;
			line-height: 19px;
			text-align: right;
			text-decoration-line: underline;
		}
	}

	.copy_btn {
		cursor: pointer;

		i {
			font-size: 22px;
		}

		&:hover {
			i {
				color: var(--color-blue);
			}
		}
	}
}

.about_container {
	@include flexbox(flex-start, flex-start, column);

	margin: 0;
	padding: 18px 40px 18px 60px;
	position: relative;
	width: 100%;

	> i {
		font-size: 20px;
		position: absolute;
		top: 22px;
		left: 20px;
	}

	.content {
		@include flexbox(flex-start, flex-start, column);
	}

	.description {
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 23px;
		color: var(--color-secondary);
		margin-bottom: 30px;
	}

	.step {
		&:nth-of-type(1):after,
		&:nth-of-type(2):after {
			background: linear-gradient(to right, transparent 0%, #f79319 31%);
		}
	}
}

.rules_container {
	@include flexbox(flex-start, flex-start, column);

	color: var(--color-secondary);
	width: 100%;
	padding: 18px 40px 18px 60px;
	margin: 0;

	> i {
		font-size: 20px;
		position: absolute;
		top: 24px;
		left: 20px;
	}

	ol,
	ul {
		padding: 0 15px;
		margin: 0;

		> li {
			margin-bottom: 10px;

			> p {
				margin: 0;
			}
		}
	}

	p {
		font-size: 16px;
	}

	> p {
		margin: 0 0 10px 0;
	}

	.title {
		@include flexbox(flex-start, center);
	}
}

.prize_list_container {
	@include flexbox(flex-start, flex-start, column);

	padding: 0;
	margin: 0;

	.header {
		@include flexbox(space-between, center);

		width: 100%;
		padding: 10px 20px;
		border-bottom: 1px solid var(--divider-border);
	}

	.title_container {
		@include flexbox();

		i {
			color: var(--color-demo);
			font-size: 16px;
			margin-right: 16px;
		}
	}

	.title {
		margin: 0;
		font-size: 14px;
		line-height: 19px;
		font-weight: normal;
		color: var(--color-secondary);
	}

	.prize_fund {
		font-weight: bold;
		font-size: 24px;
		line-height: 28px;
		text-align: right;
		color: var(--color-primary);
	}

	.prizes_list_table {
		@include tiny-scrollbar();
		@include flexbox(stretch, stretch, column);

		overflow: visible;
		flex: 1;

		width: 100%;
		padding-bottom: 20px;
	}

	.table_head {
		width: 100%;
		padding: 0 22px;
	}

	.table_row {
		padding: 0 22px;
		min-height: 41px;
	}

	.small_cell {
		width: 30px;
		max-width: 30px;
		min-width: 30px;
	}

	.place_icon {
		@include flexbox(flex-start);

		&.first {
			i {
				color: var(--color-demo);
			}
		}

		&.second {
			i {
				color: #ffb275;
			}
		}

		i {
			font-size: 22px;
			color: #6d7177;
		}
	}
}

.info_container {
	display: grid;
	grid-gap: 24px;
	justify-content: center;
	justify-items: center;
	align-items: center;
	grid-template: auto / 1fr;
	width: 100%;
	padding: 25px 15px 85px;
	height: auto;
	min-height: 236px;
	margin: 0;

	&.isActive > i {
		position: absolute;
		top: 14px;
		left: 14px;
		font-size: 16px;
	}

	> i {
		position: relative;
		top: 0;
		left: 0;
		font-size: 72px;
	}

	@include max-w(xs) {
		padding: 35px 15px 85px;
	}

	.action_button {
		@include flexbox(center, center);

		position: absolute;
		width: 100%;
		padding: 0 15px;
		bottom: 15px;
	}

	.time_left {
		@include flexbox(flex-start, center, column);

		> span {
			font-size: 14px;
			line-height: 19px;
			color: var(--color-secondary);
			margin-bottom: 20px;
		}
	}

	.time_left_counters {
		@include flexbox(center, center);
	}

	.time_left_counter {
		@include flexbox(center, center, column);
		margin: 0 10px;

		span {
			&:nth-child(1) {
				font-weight: bold;
				font-size: 36px;
				line-height: 130%;
				text-align: center;
				color: var(--color-secondary);
			}
			&:nth-child(2) {
				font-size: 14px;
				line-height: 19px;
				color: var(--color-secondary);
			}
		}
	}

	.currently_participate {
		@include flexbox(center, center);

		background: rgba(255, 224, 67, 0.1);
		border: 1px solid rgba(255, 230, 157, 0.12);
		box-sizing: border-box;
		border-radius: 0 0 8px 8px;
		height: 70px;
		width: 100%;
		font-size: 14px;
		line-height: 19px;
		color: var(--color-gold-dark);
		position: absolute;
		bottom: 0;
		left: 0;
	}

	.competition_is_over {
		@include flexbox(center, center);

		background: var(--button-filled-disabled-background);
		box-sizing: border-box;
		border-radius: 0 0 8px 8px;
		font-size: 14px;
		line-height: 19px;
		height: 70px;
		width: 100%;
		color: #fff;

		position: absolute;
		bottom: 0;
		left: 0;
	}
}

.top_traders_container {
	@include flexbox(flex-start, flex-start, column);

	padding: 0;
	margin: 0;
	width: 100%;

	.header {
		@include flexbox(center, flex-start, column);

		padding: 20px;
		width: 100%;
		border-bottom: 1px solid var(--divider-border);
	}

	.title_container {
		@include flexbox(flex-start, center);

		margin-bottom: 4px;

		i {
			color: var(--color-demo);
			font-size: 16px;
			margin-right: 8px;
		}
	}

	.title {
		margin: 0;
	}

	.subtitle {
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 23px;
		color: var(--color-secondary);
		padding-left: 24px;
	}

	.table_head {
		padding: 22px;

		&.mobile {
			padding: 10px;
		}
	}

	.table_header {
		&.empty {
			width: 38px;
			max-width: 38px;
		}

		&.deals {
			max-width: 80px;
		}
	}

	.top_traders_table {
		padding-bottom: 20px;
		overflow: auto;
	}

	.table_data_uid {
		color: var(--color-secondary);

		@include max-w(xs) {
			font-size: 12px;
		}
	}

	.place_icon {
		@include flexbox(flex-start, center);

		&.first i {
			color: var(--color-demo);
		}

		&.second i {
			color: #ffb275;
		}

		i {
			font-size: 22px;
			margin-left: -5px;
			color: var(--icon-primary);
		}
	}

	.table_row {
		max-height: 41px;
		min-height: 41px;
		padding: 22px;

		&.mobile {
			max-height: 64px;
			padding: 10px;
		}
	}

	.top_traders_table_row {
		border: 1px solid;
		border-color: transparent;
		border-radius: 5px;

		&.isLeader {
			color: var(--color-demo);

			i {
				margin-left: -5px;
			}

			.table_data_uid {
				color: var(--color-demo);
			}
		}

		&.isMyPosition {
			border-color: var(--color-green);
			background-color: var(--stripped-normal);

			&:hover {
				border-color: var(--color-green);
				background-color: var(--stripped-normal);
			}

			i {
				margin-left: -5px;
			}
		}

		&:hover {
			background-color: transparent;
			border-color: var(--color-demo);
		}

		@include max-w(xs) {
			//${TableData} {
			font-size: 14px;
		}
	}

	.table_data {
		&.icon {
			min-width: 35px;
			width: 35px;
			max-width: 35px;
		}

		&.trades_count {
			max-width: 80px;
		}
	}

	.my_position_icon {
		display: inline-flex;
		justify-content: flex-start;
		align-items: center;
		vertical-align: middle;
		margin: 0 5px 2px 0;

		i {
			font-size: 20px;
			margin-left: -5px;
			color: var(--color-green);
		}
	}

	.my_position_label {
		color: var(--color-green);
	}

	.current_balance {
		@include flexbox(flex-end, center);

		@include max-w(xs) {
			@include flexbox(flex-start, flex-end, column);
		}

		span:last-child {
			margin-left: 3px;
		}
	}
}

.demo_description_container {
	@include flexbox(flex-start, flex-start, column);

	width: 100%;
	margin: 0;
	padding: 30px;
	background: transparent;
	border: none;

	.step_title {
		//styled(PageStepTitle)`
		font-size: 24px;
		margin-bottom: 8px;
	}

	.page_steps {
		display: grid;
		grid-template: auto / 1fr 1fr;
		grid-row-gap: 56px;
		justify-items: center;

		@include max-w(lg) {
			grid-template: auto / 1fr;
			margin-bottom: 0;
		}
	}

	.page_step {
	}
}
