@import "src/assets/styles/common/mixins";

.container {
	@include flexbox(stretch, stretch, column);
}

.upload_input {
	@include flexbox(center, center, column);

	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 5px;
	padding: 20px 10px;
	text-align: center;
	font-size: 16px;
	line-height: 19px;
	cursor: pointer;
	outline: none;
	border: 1px solid var(--color-blue);
	color: var(--color-blue);

	&.error {
		border-color: var(--input-error);
		color: var(--input-error);

		svg {
			color: var(--input-error);
		}

		i {
			color: var(--input-error);
		}
	}

	&.read_only {
		pointer-events: none;
	}

	svg {
		margin-bottom: 30px;

		@include max-w(xs) {
			max-width: 41px;
		}

		&.active_drag {
			border-color: var(--color-focus);
		}
	}

	i {
		color: var(--color-blue);
	}
}

.loader {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	border-radius: 10px;
}

.spinner {
	display: inline-block;
	width: 80px;
	height: 80px;
}

.error_message {
	padding: 10px 0 0;
	font-size: 12px;
	line-height: 1.3;
	color: var(--input-error);
}
