@import "src/assets/styles/common/mixins";

.container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 16px;
	width: 100%;
	margin-bottom: 25px;

	@include max-w(md) {
		grid-template-columns: 1fr;
		padding: 0 20px;
	}
}

.stats_card {
	@include card();
	display: flex;
	flex-direction: column;
	padding: 0;
	width: auto;

	@include max-w(md) {
		border: 1px solid var(--card-outlined-border-color);
	}
}

.card_header {
	@include card-header();
	@include flexbox(flex-start, center);
	padding: 10px 27px;
	min-height: 60px;

	@include max-w(xxl) {
		min-height: 55px;
	}
}

.stats_card_counters {
	@include flexbox(space-between, center);
	flex-wrap: wrap;
	width: 100%;
	padding: 20px 27px 28px;

	@include max-w(md) {
		@include flexbox(flex-start, flex-start, column);
		padding: 20px 20px 25px;
	}
}

.stats_card_counter {
	@include flexbox(space-between, flex-start, column);
	width: 50%;

	span {
		font-weight: normal;
		font-size: 16px;
		line-height: 23px;
		color: var(--color-secondary);
	}

	&:nth-of-type(2) {
		padding-left: 100px;
	}

	@include max-w(md) {
		width: 100%;

		&:not(:last-child) {
			margin-bottom: 20px;
		}

		&:nth-of-type(2) {
			padding-left: 0;
		}
	}
}

.stats_card_counter_value {
	@include flexbox(flex-start, center);
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 28px;
	color: var(--color-primary);
	margin-bottom: 30px;

	@include max-w(xxl) {
		margin-bottom: 20px;
	}

	@include max-w(md) {
		margin-bottom: 10px;
	}
}

.card_title {
	@include card-title();
}

.divider {
	height: 1px;
	overflow: hidden;
	background: var(--divider-border);
	flex: 0 1 auto;
}

.tooltip {
	position: relative;
	width: max-content;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 7px;
	cursor: pointer;
	min-width: 30px;
	min-height: 30px;

	i {
		font-size: 12px;
		color: var(--tooltip-icon-color);
	}

	i:hover {
		color: var(--tooltip-icon-color-hover);
	}
}
