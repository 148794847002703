@import "src/assets/styles/common/mixins";

.wrapper {
	@include flexbox(flex-start, flex-start, column);
	width: 100%;
	max-width: 2560px;
	margin: 0 auto;
	padding: 20px;

	> div:not(:last-child) {
		margin-bottom: 20px;
	}
}

.container {
	@include card();
	margin: 0 auto;
	padding: 20px;
	max-width: get-breakpoint-value(xxxl);
	width: 100%;
}

.sections_nav {
	@include flexbox(space-between, flex-end);
	width: 100%;
	padding: 0 20px;
}

.sections_nav_tabs {
	border-bottom: none;
	height: 42px;
	min-height: 42px;

	> div {
		height: 42px;
		min-height: 42px;
	}
}

.header {
	width: 100%;
	@include flexbox(flex-start, flex-start, column);

	h1 {
		margin: 0 0 12px 0;
		color: var(--color-primary);
		@include font-size(
			(
				xs: 32px,
				xxxl: 42px,
			),
			42px
		);
	}

	span {
		color: var(--color-secondary);
		@include font-size(
			(
				xs: 14px,
				xxxl: 16px,
			),
			16px
		);
	}
}

.search_block {
	padding: 20px;
	width: 100%;
	display: grid;
	grid-template: 1fr / 1fr 180px auto auto;
	grid-gap: 20px;
	position: relative;
	z-index: 1;

	> button {
		min-width: 140px;
	}

	@include max-w(lg) {
		grid-gap: 15px;
		grid-template: auto / 1fr;
	}
}

.fee_info {
	@include flexbox(center, center);
	border: 1px solid var(--input-disabled);
	border-radius: 12px;
	background-color: var(--background-color);
	padding: 5px 10px;
	min-width: 150px;
	min-height: 46px;
}
.fee {
	font-weight: 500;
	margin-right: 20px;
	color: var(--color-primary);
}

.balance_label {
	font-size: 12px;
	color: var(--color-primary);
}

.balance {
	font-size: 12px;
	color: var(--color-primary);
}

.search_block_login {
	@include flexbox(center, center);
	position: absolute;
	padding: 20px;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	color: var(--color-blue);
	background: var(--content-blur);
	backdrop-filter: blur(3px);
	z-index: 4;
	border-radius: 0 0 12px 12px;

	span {
		text-decoration: underline;
		font-size: 16px;
	}

	i {
		color: var(--color-blue);
		margin: 0 4px 1px 0;
	}
}

.button_panel {
	position: fixed;
	bottom: 50px;
	right: 50px;
	display: flex;
	z-index: 20;

	:not(:first-child) {
		margin-left: 10px;
	}
}

.report {
	border-radius: 3px;
	padding: 24px;
}

.circle_progress {
	svg {
		width: 100%;
	}
}

.a-circle-progress-wrapper {
	.circle-progress {
		flex-direction: column;
	}
}

.a-circle-progress-wrapper {
	.circle-progress {
		.progress-content {
			.inner-default-percentage {
				align-items: flex-end;
			}
		}
	}
}

.empty {
	@include flexbox(flex-start, center, column);
	margin: 0 auto;
	color: var(--color-secondary);
	text-align: center;
	font-size: 16px;
	line-height: 1.3;
	padding: 20px;
	max-width: get-breakpoint-value(xxxl);
	width: 100%;

	span {
		margin-bottom: 4px;
	}

	button {
		color: var(--color-blue);
		font-size: 16px;
		text-decoration: underline;
	}
}

.info_label {
	text-align: center;
}
