@import "assets/styles/common/mixins";

.container {
	@include flexbox(flex-start, flex-start, column);
	position: relative;
	width: 100%;

	&.md {
		max-width: 700px;
	}

	@include min-w(xxl) {
		width: calc(100% - 220px);
	}

	@include max-w(xxl) {
		padding: 10px;
	}

	@include max-w(xs) {
		padding: 0;
	}
}

.header {
	@include flexbox(space-between, flex-end);

	padding: 30px 24px;
	background-color: var(--card-background-color);
	border-radius: 8px;
	border: 1px solid var(--card-border-color);
	position: relative;
	width: 100%;
	margin: 10px 0;

	@include max-w(lg) {
		@include flexbox(flex-start, flex-start, column);

		padding: 24px;
		height: auto;
	}

	&.balance {
		padding: 15px 25px 35px;
	}

	&.api {
		align-items: flex-start;
	}

	&.api_details {
		align-items: stretch;
		padding: 15px 30px 30px;
	}

	h1 {
		font-weight: bold;
		font-size: 36px;
		line-height: 1.3;
		color: var(--color-primary);
		margin: 0 0 15px 0;
	}

	h2 {
		max-width: 900px;
		width: 100%;
		font-size: 14px;
		line-height: 1.3;
		color: var(--color-secondary);
	}

	@include max-w(md) {
		border-color: transparent;
	}

	@include max-w(xs) {
		margin: 0;
		border-radius: 0;
		border: none;
	}
}

.card_container {
	@include card(true);
	@include flexbox(flex-start, center, column);
	margin: 0;
	padding: 50px 150px;
	width: 100%;

	@include max-w(xs) {
		padding: 24px;
	}

	> h3 {
		text-align: center;
	}
}

.card_title {
	@include card-title();

	text-align: center;
	font-size: 36px;
}

.card_subtitle {
	@include card-subtitle();

	text-align: center;
}

.table_container {
	@include card();

	overflow: initial;
	width: 100%;
	margin: 0 0 10px;
	padding: 0;

	@include max-w(xs) {
		border-radius: 0;
	}
}

.filters {
	@include flexbox(flex-start, flex-end);

	width: 100%;
	padding: 24px 34px;

	> div,
	> button {
		margin-right: 24px;
	}

	@include max-w(md) {
		flex-direction: column;

		> div,
		> button {
			margin-right: 0;
			width: 100%;
			margin-bottom: 12px;
		}
	}

	@include max-w(xs) {
		padding: 10px;
	}
}

.filters_buttons {
	display: grid;
	grid-template: auto / 1fr 1fr;
	grid-gap: 24px;

	@include max-w(md) {
		grid-gap: 12px;

		button {
			width: 100%;
		}
	}
}

.filter_select {
	width: 165px;
	background: var(--card-background-color);

	@include max-w(md) {
		width: 100%;
	}
}

.filter_checkbox {
	@include flexbox(center, center);

	height: 36px;
}

.pagination_container {
	padding: 20px 34px;
}

.table_title {
	display: flex;
	font-size: 24px;
	font-weight: 700;
	color: var(--color-primary);

	&.margin {
		margin: 25px 34px 15px;
	}

	@include max-w(xs) {
		margin: 10px;
	}
}

.grey_text {
	color: var(--color-secondary);
}

.table_data_status {
	font-size: 14px !important;

	&[class~="ai-check_filled"] {
		color: var(--color-green);
	}

	&[class~="ai-cancel_filled"] {
		color: var(--color-red);
	}
}

.action_button {
	@include flexbox(flex-end, center);

	margin: 0 auto;

	i {
		font-size: 12px;
		margin-right: 8px;
		color: var(--color-blue) !important;
	}

	span {
		color: var(--color-blue);
	}

	&.delete {
		margin: 0 0 0 auto;

		i {
			color: var(--color-red) !important;
		}
		span {
			color: var(--color-red);
		}
	}
}

.breadcrumbs_container {
	padding: 20px 30px 0;
}

.breadcrumbs_edit_api {
	margin-top: 10px;
}

.separator {
	margin: 0 0 15px;
	width: 100%;
	background-color: var(--card-border-color);
	height: 1px;

	@include max-w(md) {
		display: none;
	}
}

.sub_authenticator {
	display: grid;
	grid-template: min-content / 1fr;
	margin: 0 auto;
	padding: 30px 24px;
}

.copy_btn {
	margin-left: 10px;
	cursor: pointer;
	color: var(--color-blue) !important;
	font-size: 14px;
}

.header_title_container {
	@include flexbox(flex-start, flex-start, column);
}

.date_picker {
	margin-bottom: 5px;
}

.table_row {
	padding: 0 30px;
}

.tabs {
	height: 42px;
	padding-top: 10px;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
}

.gold_icon {
	color: var(--color-gold);
}

.modal_form_content {
	padding: 18px 30px;
}
