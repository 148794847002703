@import "src/assets/styles/common/mixins";

.coins_container {
	@include flexbox(flex-start, flex-start, column);
	margin: 10px 0;

	> span {
		font-weight: normal;
		font-size: 14px;
		line-height: 19px;
		margin-bottom: 10px;
		color: var(--color-secondary);
	}
}

.coins {
	width: 100%;
	display: grid;
	grid-template: auto / 1fr 1fr;
	grid-gap: 25px;
}

.info_container {
	width: 100%;
	padding: 20px;
	background: var(--stripped-normal);

	@include max-w(md) {
		padding: 15px 30px;
	}
}

.isolated_select_container {
	width: 100%;
	display: grid;
	grid-template: auto / 1fr 160px;
	grid-gap: 16px;
}

.transfer_to_container {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	position: relative;

	> button {
		outline: none;
		padding: 0;
		margin: 0 auto 20px;
		width: 34px;
		height: 34px;
		border-radius: 8px;

		i {
			font-size: 24px;
			color: var(--color-blue);
		}

		&:hover {
			background: var(--tab-button-background-hover);
		}
	}
}

.amount_container {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.available_amount {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	font-size: 12px;
	line-height: 14px;
	text-align: right;
	color: var(--color-secondary);
	margin-bottom: 6px;

	button {
		font-weight: normal;
		font-size: 14px;
		line-height: 19px;
		text-align: right;
		outline: none;
		padding: 0;
		margin: 0;
		color: var(--color-blue);
	}
}

.transfer_info_container {
	width: 100%;
	padding: 20px;
	display: grid;
	grid-template: auto / 1fr 1fr;
	grid-gap: 12px;
	background: var(--stripped-normal);

	@include max-w(md) {
		padding: 20px 30px;
	}

	> span {
		font-weight: normal;
		font-size: 12px;
		line-height: 14px;
		color: var(--color-secondary);
		display: flex;
		justify-content: flex-start;
		align-items: center;

		&:nth-child(2n) {
			font-size: 16px;
			line-height: 19px;
			color: var(--color-primary);
			justify-content: flex-end;
		}
	}
}

.stop_limit_confirm_subheader {
	@include flexbox(flex-start, center);
	width: 100%;
	padding: 20px;
	color: var(--color-primary);
}

.stop_limit_stop_order_text {
	color: var(--color-secondary);
	text-align: left;
	padding: 20px;

	> span {
		color: var(--color-primary);
	}
}
