@import "src/assets/styles/common/mixins";

.card {
	@include card();
	@include flexbox(flex-start, center, column);
	width: 100%;
	padding: 45px 90px;

	> img {
		margin: 40px auto;
		user-select: none;
	}

	@include max-w(xs) {
		padding: 24px;
		margin: 0;
	}
}

.title {
	font-weight: bold;
	font-size: 36px;
	line-height: 42px;
	text-align: center;
	margin: 0;
}

.subtitle {
	text-align: center;
	font-size: 16px;
	line-height: 22px;
	margin: 0 auto 24px;
	max-width: 600px;
}
