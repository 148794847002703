@import "assets/styles/common/mixins";

.container {
	@include flexbox(stretch, stretch, column);
}

.checkbox {
	@include flexbox(stretch, flex-start);

	position: relative;
	cursor: pointer;
	user-select: none;
	text-align: left;
	line-height: 1.3;
	font-size: 14px;
	color: var(--color-secondary);

	a,
	a:active,
	a:visited {
		color: var(--color-blue);
	}

	a:hover {
		text-decoration: underline;
	}

	&.disabled {
		color: var(--color-disabled);
	}

	&.promo {
		a,
		a:active,
		a:visited {
			color: var(--color-promo-gold);
		}
	}

	&.centered {
		align-items: center;

		.checkmark {
			margin-bottom: 3px;
		}
	}

	&.error {
		.checkmark {
			border-color: var(--input-error);
		}

		span {
			color: var(--input-error);
		}
	}
}

.input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	left: 5px;
	top: auto;
	background-color: transparent;
	border: 1px solid;
	border-color: var(--color-secondary);

	&.checked {
		background-color: var(--color-blue);
		border-color: var(--color-blue);
	}

	&.disabled {
		background-color: var(--icon-disabled);
		border-color: transparent;
	}
	&:focus + span:before {
		display: block;
	}
}

.checkmark {
	border-radius: 4px;
	flex: 0 0 20px;
	height: 20px;
	width: 20px;
	background-color: transparent;
	border: 1px solid;
	border-color: var(--input-normal);
	box-sizing: border-box;
	margin-right: 8px;
	margin-bottom: 2px;
	position: relative;

	&:before {
		content: "";
		position: absolute;
		width: calc(100% + 6px);
		height: calc(100% + 6px);
		left: -3px;
		top: -3px;
		display: none;
		border-radius: 8px;
		box-sizing: border-box;
		border: 2px solid var(--color-focus);
	}

	&.checked {
		background-color: var(--color-blue);
		border-color: var(--color-blue);

		&:after {
			content: "";
			display: block;
			position: absolute;
			width: 5px;
			left: 7px;
			top: 3px;
			height: 9px;
			border: solid #fff;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
		}

		&.promo {
			background-color: var(--color-promo-gold);
			border-color: var(--color-promo-gold);
		}
	}

	&.disabled {
		background-color: var(--icon-disabled);
		border-color: transparent;
	}
}

.error_and_help_text {
	margin-top: 10px;
}
